import React from 'react'

import styles from './scoop-list.module.scss'

export interface IScoopListProps {
    data: Array<any>;
    selectedId: number;
    onClick: (id: number) => void
}

export const ScoopList: React.FC<IScoopListProps> = ({
    data = [],
    selectedId = null,
    onClick = undefined
}) => {
    return (
        <ul className={styles['list']}>
            {data.map((listItem) => (
                <li
                    key={listItem.id}
                    className={`${styles['list-item']} ${listItem.id === selectedId ? styles["list-item-selected"] : ""} ${(!listItem.unitCost || !listItem.isActive) ? styles["list-item-disabled"] : ""}`}
                    onClick={() => listItem.unitCost.toFixed(2) ? onClick(listItem.id) : undefined}
                >
                    <div className={styles['list-item-colors']}>
                        {listItem.colors?.map((color, index) =>
                            <div key={index} style={{ backgroundColor: color }}></div>
                        )}
                    </div>
                    <div className={styles['list-item-content']}>
                        <div className={`small-text ${styles['list-item-header']}`}>
                            {listItem.name}
                        </div>
                        <div className={`title_text_3 ${styles['list-item-price']}`}>
                            {listItem.unitCost ? '£' + listItem.unitCost.toFixed(2) : '-'}
                        </div>
                    </div>
                </li>
            ))
            }
        </ul >
    )
}

export default ScoopList