import React from 'react';

interface IClockProps {
    fillColor?: string;
}

const Clock: React.FC<IClockProps> = ({
    fillColor = "#DF668A"
}) => {
    return (
        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_207_12)">
                <path d="M9.00001 20.099C13.467 20.099 17.0882 16.5214 17.0882 12.1081C17.0882 7.69475 13.467 4.11707 9.00001 4.11707C4.53305 4.11707 0.911865 7.69475 0.911865 12.1081C0.911865 16.5214 4.53305 20.099 9.00001 20.099Z" stroke={fillColor} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                <path d="M9 4.1171V0.900879" stroke={fillColor} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                <path d="M5.60791 0.900879H12.3556" stroke={fillColor} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                <path d="M9 12.1082L12.2188 8.92798" stroke={fillColor} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                <path d="M15.0456 6.13519L16.4499 4.7478" stroke={fillColor} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
            </g>
            <defs>
                <clipPath id="clip0_207_12">
                    <rect width="18" height="21" fill={fillColor} />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Clock;

