import { Grid } from '@mui/material';
import React from 'react'

import ButtonIncDec from './../../../reusable/components/ButtonIncDec/button-increment';
import Close from './../../../reusable/components/icons/Close/close';
import styles from './basket-list.module.scss'

export interface IBasketListProps {
    data: Array<any>;
    onIncDec: (id: string, condition: "decrement" | 'increment') => void;
    handleRemove: (id: number) => void;
    disabled?: boolean
}

export const BasketList: React.FC<IBasketListProps> = ({
    data = [],
    onIncDec = undefined,
    handleRemove = undefined,
    disabled = false,
}) => {
    return (
        <ul className={styles['list']}>
            <Grid container spacing={4}>
                {data.map((listItem) => (
                    <Grid item xs={12} sm={6} lg={4} key={listItem.id}>
                        <li key={listItem.id} className={`${styles['list-item']}`}>
                            <div className={styles['list-item-image']}>
                                <img src={listItem.image} alt={listItem.imagePath} />
                            </div>
                            <div className={styles['list-item-content']}>
                                <button disabled={disabled} type="button" className={`${styles['close-icon']}`} onClick={() => handleRemove(listItem.id)}>
                                    <Close />
                                </button>
                                <h4 className={`small-text ${styles['list-item-header']}`}>
                                    {listItem.count} x {listItem.name}
                                </h4>
                                {listItem.children?.length ?
                                    <div className={`heading-text ${styles['list-item-list']}`}>
                                        {listItem.children.map(listChild =>
                                            <p className='small-text' key={listChild.name}>{listChild.count == 0 ? 1 : listChild.count} x {listChild.name}</p>
                                        )}
                                    </div>
                                    : null}
                                <div className={`${styles['list-item-actions']}`}>
                                    <div className={`title_text_2 ${styles['list-item-price']}`}>
                                        £{(listItem.count * listItem.price).toFixed(2)}
                                    </div>
                                    <div className={`${styles['increment-container']}`}>
                                        <ButtonIncDec
                                            value={listItem.count}
                                            onIncrement={() => onIncDec(listItem.id, 'increment')}
                                            onDecrement={() => onIncDec(listItem.id, 'decrement')}
                                            disabled={disabled}
                                            classes={{
                                                root: styles['increment-button']
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </li>
                    </Grid>
                ))}
            </Grid>
        </ul>
    )
}

export default BasketList