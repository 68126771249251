import React from 'react';

interface IArrowLeftProps {
    strokeColor?: string;
}

const ArrowLeft: React.FC<IArrowLeftProps> = ({
    strokeColor = "#29373C"
}) => {
    return (
        <svg width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.21419 1.92261C5.43892 3.79603 3.73681 5.06152 1.83696 6.82479C1.53476 7.10527 0.648576 7.50348 1.15123 7.84241C2.14759 8.51425 3.02975 9.97388 3.86392 10.8125C4.51014 11.4621 5.12742 12.2383 5.80956 12.8413C6.26966 13.248 6.77967 13.5689 7.19604 13.9999" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" />
        </svg>
    );
};

export default ArrowLeft;

