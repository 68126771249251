/* eslint-disable no-console */
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";

import AwardWinning from "../../../assets/images/award-winning.svg";
import FacebookIcon from "../../../assets/images/facebook.svg";
import InstagramIcon from "../../../assets/images/instagram.svg";
import PlayChess from "../../../assets/images/play-chess.svg";
import ShopImage from "../../../assets/images/shop-white.svg";
import StickyPaws from "../../../assets/images/sticky-paws.svg";
import TwitterIcon from "../../../assets/images/twitter.svg";
import YoutubeIcon from "../../../assets/images/youtube.svg";
import Button from "../../reusable/components/Button/button";
import Logo from "../../reusable/components/icons/Logo/logo";
import SectionSuccessArrow from "../../reusable/components/icons/SectionSuccessArrow/section-success-arrow";
import ShopService from "../../services/ShopService";
import ArrowRight from "./../../reusable/components/icons/ArrowRight/arrow-right";
import Clock from "./../../reusable/components/icons/Clock/clock";
import styles from "./success.module.scss";

function Success() {
  const { orderId } = useParams();
  const [emailValue, setEmailValue] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [waitTimes, setWaitTimes] = useState<any[]>();

  useEffect(() => {
    loadAllWaitingTimes();
  }, []);

  const loadAllWaitingTimes = async () => {
    await ShopService.allWaitingTimes().then((response) => {
      setWaitTimes(response.data);
    });
  };

  const validateEmail = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailValue)) {
      return true;
    }
    return false;
  };

  const onSignUp = () => {
    const isValid = validateEmail();

    if (isValid) {
      setDisabled(true);
      console.log(emailValue);

      setTimeout(() => {
        setEmailValue("");
        setDisabled(false);
      }, 1000);
    }
  };

  const socials = [
    { url: "https://www.facebook.com/IceCreamFarm/", icon: FacebookIcon },
    { url: "https://www.instagram.com/theicecreamfarm", icon: InstagramIcon },
    {
      url: "https://www.youtube.com/channel/UCkfJUFI3tVOSwBavaEx9Oug",
      icon: YoutubeIcon,
    },
    { url: "https://twitter.com/theicecreamfarm", icon: TwitterIcon },
  ];

  const similarOffers = [
    {
      img: StickyPaws,
      url: "https://www.theicecreamfarm.co.uk/food/",
      header: "Why not try Sticky Paws?",
      desc: "We know how stressful eating out with kids can be, so we've created the perfect solution!",
    },
    {
      img: PlayChess,
      url: "https://www.theicecreamfarm.co.uk/play-pass/",
      header: "Enjoy Savings with our Play Pass",
      desc: "The Play Pass offers you a great way to save on the cost of play for all our attractions onsite",
    },
    {
      img: AwardWinning,
      url: "https://www.theicecreamfarm.co.uk/ice-cream/",
      header: "All of your favourite Award- Winning flavours",
      desc: "From tasty Bueno to Jammy Dodger which flavour will you choose?",
    },
  ];

  return (
    <div className={styles["container"]}>
      <div className={styles["top-section"]}>
        <div className={styles["top-section-centent"]}>
          <Link to="/" className={styles["main-logo"]}>
            <Logo fillColor="#ffffff" />
          </Link>
          <img
            src={ShopImage}
            alt="Shop image"
            className={styles["shop-image"]}
          />
          <div className={styles["success-message"]}>
            <h4 className="title_text_1">We're on it!</h4>
            <p className="body-text">
              Stay put, we'll bring your <br /> order to your vehicle!
            </p>
            {!!orderId && (
              <p className={`body-text ${styles["order-code"]}`}>#{orderId}</p>
            )}
          </div>
          <div className={styles["wait-time"]}>
            <div className={styles["heading"]}>
              <Clock />
              <span className={`small-text ${styles["wait-time-text"]}`}>
                Current Wait Times:
              </span>
            </div>

            {waitTimes &&
              waitTimes?.map((item) => (
                <p
                  key={item.id}
                >{`${item.description}: ${item.waitInMinutes} mins`}</p>
              ))}
          </div>
        </div>
        <div className={styles["top-section-arrow"]}>
          <SectionSuccessArrow />
        </div>
      </div>
      <div className={styles["similar-offers-container"]}>
        {similarOffers.map((offer, index) => (
          <a
            href={offer.url}
            target="_blank"
            rel="noopener noreferrer"
            className={styles["offer-item"]}
            key={index}
          >
            <img src={offer.img} alt="similar offer image" />
            <div className={styles["offer-info"]}>
              <h1 className={`small-text ${styles["offer-header"]}`}>
                {offer.header}
              </h1>
              <p className={`small-text ${styles["offer-description"]}`}>
                {offer.desc}
              </p>
              <div className={`small-text ${styles["offer-info-url"]}`}>
                Find out more <ArrowRight />
              </div>
            </div>
          </a>
        ))}
      </div>
      <div className={styles["footer"]}>
        <h1 className="title_text_2">Follow the farm!</h1>
        <div className={styles["social-networks"]}>
          {socials.map((social, index) => (
            <div className={styles["social-item"]} key={index}>
              <a href={social.url} target="_blank" rel="noopener noreferrer">
                <img src={social.icon} alt={"social network icon"} />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Success;
