import React from 'react'

import Header from './Header/header'
import styles from './layout.module.scss'

const Layout = ({ children, backLink=undefined }) => {
    return (
        <div className={styles['container']}>
            <Header backLink={backLink} />
            <div className={styles['main-content']}>
                {children}
            </div>
        </div>
    )
}

export default Layout