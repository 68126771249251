import React, {useRef, useState} from "react";

import Button from "../../../../reusable/components/Button/button";
import SectionHeading from "../../../../reusable/components/SectionHeading/section-heading";
import CurrentlySelected from "../CurrentlySelected/CurrentlySelected";
import ChildCategory from "./ChildCategory/ChildCategory";
import styles from "./MasterCategory.module.scss";
import Products from "./Products/Products";

const MasterCategory = ({
    selectionText='',
    children = [],
    onCategorySelected=undefined,
    onProductSelected=undefined,
    selectedProductId='',
    selectedCategoryId='',
    handleStep=undefined,
    scrollTop=undefined,
    setStep=undefined,
    selectedCategoryName='',
    selectedProductName=''
                        }) => {
    const [products, setProducts]=useState([]);
    const [productSelectionText, setProductSelectionText]=useState('');
    
    const productRef = useRef(null);
    const endRef = useRef(null);
    
    const categorySelected = (category)=>{
        setProductSelectionText(category.selectionText);
        
        setProducts(prepareProducts(category.products));
        onCategorySelected(category);
        setTimeout(()=>{
            productRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });    
        }, 100);
    }
    
    const productSelected=(product)=>{
        onProductSelected(product);
        setTimeout(()=>{
            productRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100);
    }

    const prepareProducts = (cones) => {
        return cones.map((cone) => {
            switch (cone.tag) {
                case "quadtriple-scoop":
                    cone.colors = ['#CBA8D2', '#FFB7AE ', '#BDE18A', '#E27EA6'];
                    break;
                case "triple-scoop":
                    cone.colors = ['#CBA8D2', '#FFB7AE ', '#BDE18A'];
                    break;
                case "double-scoop":
                    cone.colors = ['#CBA8D2', '#FFB7AE'];
                    break;
                default:
                    cone.colors = ['#CBA8D2'];
                    break;
            }

            return cone;
        })
    }
    
    return (
    <>
        <div className={styles['section-header']}>
            <SectionHeading header={selectionText} />
        </div>
        <ChildCategory data={children} onClick={categorySelected} selectedId={selectedCategoryId} />
        
        <div className={styles['section-header']} ref={productRef}>
            <SectionHeading header={productSelectionText} />
        </div>
        <Products products={products} selectedId={selectedProductId} onClick={productSelected}  />

        {selectedProductId!='' ?
            <div className={styles['next-steps-container']} ref={endRef}>
                <CurrentlySelected 
                    selectedCategoryName={selectedCategoryName} 
                    selectedProductName={selectedProductName}
                    selectedOptions={[]}
                    selectedExtras={[]}
                    />
                <div className={styles['button-container']}>
                    <Button
                        text="Choose Flavours"
                        classes={{
                            root: styles['choose-button']
                        }}
                        onClick={() => {
                            scrollTop();
                            handleStep(1)
                            setStep('options')
                        }}
                    />
                </div>
            </div>
            : null}
    </>);
}

export default MasterCategory;