import React from "react";

import styles from "./Products.module.scss";

const Products = ({
    products,
    selectedId,
    onClick
}) => {
    return (<div className={styles["list-wrapper"]}><ul className={styles['list']}>
        {products.map((listItem) => (
            <li
                key={listItem.id}
                className={`${styles['list-item']} ${listItem.id === selectedId ? styles["list-item-selected"] : ""} ${(!listItem.unitCost || !listItem.isActive) ? styles["list-item-disabled"] : ""}`}
                onClick={() => listItem.unitCost.toFixed(2) ? onClick(listItem) : undefined}
            >
                <div className={styles['list-item-colors']}>
                    {listItem.tag=="image" ? <img src={listItem.imagePath} alt={listItem.name}/> : listItem.colors?.map((color, index) =>
                        <div key={index} style={{ backgroundColor: color }}></div>
                    )}
                </div>
                <div className={styles['list-item-content']}>
                    <div className={`small-text ${styles['list-item-header']}`}>
                        {listItem.name}
                    </div>
                    <div className={`title_text_3 ${styles['list-item-price']}`}>
                        {listItem.unitCost ? '£' + listItem.unitCost.toFixed(2) : '-'}
                    </div>
                </div>
            </li>
        ))
        }
    </ul></div>)
};

export default Products;