import React from 'react';

import styles from './text-field.module.scss';

export interface ITextFieldProps<T = any> {
  value?: T;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  type?: string;
  id?: string;
  className?: string;
  autoFocus?: boolean;
  error?: any
}

export const TextField: React.FC<ITextFieldProps> = ({
  value = '',
  onChange = undefined,
  name = '',
  label = undefined,
  placeholder = undefined,
  disabled = false,
  readOnly = false,
  id = undefined,
  type = 'text',
  className = '',
  autoFocus = false,
  error
}) => (
  <div className={styles['container']}>
    {label ?
      <div
        className={`title_text_2 ${styles['label']}`}>
        {label}
      </div>
      : null}
    <input
      id={id}
      type={type}
      name={name}
      placeholder={placeholder}
      disabled={disabled}
      value={value}
      onChange={onChange}
      className={`body-text ${styles['input']} ${error ? styles['input-error'] : ''} ${className}`}
      autoFocus={autoFocus}
      readOnly={readOnly}
      autoComplete={'none'}
    />
    {error?.length ? <span className={`body-text ${styles['error-message']}`}>{error}</span> : null}
  </div>
);

export default TextField;
