import React, {useContext, useEffect, useState} from "react";
import {Navigate} from "react-router";
import {useParams} from "react-router-dom";
import {SavedLocationContext} from "../../app.index";

const RememberLocation = () => {
    const [savedLocation, setSavedLocation]=useState(false);
    const {id} = useParams();
    
    let {setLocationSaved} = useContext(SavedLocationContext);
    
    useEffect(()=> {
        setLocationSaved(parseInt(id));
        setSavedLocation(true);
    }, [id]);
    
    return (savedLocation ? <Navigate to={"/"} /> : <></>)
}

export default RememberLocation;