import { Grid } from '@mui/material';
import React, {useContext, useState} from 'react'

import Warning from '../../../reusable/components/icons/Warning/warning';
import TextArea from '../../../reusable/components/Textarea/text-area';
import TextField from './../../../reusable/components/TextField/text-field';
import styles from './basket-form.module.scss'
import VenuNumber from './VenuNumber/venu-number';
import {SavedLocationContext} from "../../../app.index";

export interface IBasketFormProps {
    formValues: any;
    handleChangeField: (e: any) => void;
    numList: Array<any>;
    handleSelectVanuNumber?: (id: number) => void;
    selectedVanuNumber?: number,
    venueNumberInvalid: any
}

export const BasketForm: React.FC<IBasketFormProps> = ({
    formValues,
    handleChangeField = undefined,
    numList = [],
    selectedVanuNumber = null,
    handleSelectVanuNumber = undefined,
    venueNumberInvalid = null
}) => {
    const [venuListOpened, setVenuListOpened] = useState(false)

    return (
        <div className={styles['container']}>
            <div className={styles['form']}>
                <Grid container spacing={4}>
                    <Grid item xs={12} lg={12}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={8} md={8} lg={4}>
                                <div className={styles['area-number-container']}>
                                    <div className={styles['area-number-header']}>
                                        <Warning />
                                        <p className={`${styles["body-text"]} ${venueNumberInvalid === true ? styles["error"] : ""}`}>
                                            Please select your location number below
                                        </p>
                                    </div>
                                    <div className={styles['area-number-content']}>
                                        <VenuNumber
                                            numList={numList}
                                            selectedVanuNumber={selectedVanuNumber}
                                            handleSelectVanuNumber={handleSelectVanuNumber}
                                            venuListOpened={venuListOpened}
                                            setVenuListOpened={setVenuListOpened}
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={4} lg={4}>
                        <TextField
                            type='text'
                            name="firstName"
                            label='First Name'
                            placeholder='Enter first name'
                            onChange={handleChangeField}
                            value={formValues.firstName}
                            error={formValues.firstNameErr}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4} lg={4}>
                        <TextField
                            type='text'
                            name="lastName"
                            label='Last Name'
                            placeholder='Enter last name'
                            onChange={handleChangeField}
                            value={formValues.lastName}
                            error={formValues.lastNameErr}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4}>
                        <TextField
                            type='tel'
                            name="phoneNumber"
                            label='Phone Number'
                            placeholder='Enter phone number'
                            onChange={handleChangeField}
                            value={formValues.phoneNumber}
                            error={formValues.phoneNumberErr}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4}>
                        <TextArea
                            name="notes"
                            label='Notes'
                            placeholder='Enter order notes, please include any allergies or intolerances you may have'
                            onChange={handleChangeField}
                            value={formValues.notes}
                        />
                    </Grid>
                </Grid>
            </div>
            <div className={styles['allergy-warning']}>
                <Warning />
                <p>
                    Any serious allergy queries please call
                    <a href="tel:08000282023">0800 0282023</a>
                </p>
            </div>
        </div>
    )
}

export default BasketForm