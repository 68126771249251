import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../../../../reusable/components/Button/button";
import SectionHeading from "../../../../reusable/components/SectionHeading/section-heading";
import BasketCtx from "../../../../reusable/context/BasketContext";
import styles from "../../../IceCream/IceCreamBasket/basket.module.scss";
import BasketConfirmationLine from "./BasketConfirmationLine/BasketConfirmationLine";
import AllergiesModal from "../../../../reusable/components/AllergiesModal/AllergiesModal";

const BasketConfirmation = ({
  scrollTop = undefined,
  onUpdateBasketItem = undefined,
  basketId = undefined,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [notes, setNotes] = useState("");
  const navigate = useNavigate();
  const basketContext = useContext(BasketCtx);

  const handleContinue = () => {
    basketContext.setNotes(notes);
    setShowModal(false);
    navigate("/basket");
  };

  return (
    <>
      <div
        className={`${styles["section-header"]} ${styles["section-basket-header"]}`}
      >
        <SectionHeading header="Added to basket!" />
      </div>
      <div className={styles["basket-content"]}>
        <div className={styles["basket-items"]}>
          {basketContext.basketLines
            .filter((row) => row.id == basketContext.lastCreatedId)
            .map((_, index) => {
              return (
                <BasketConfirmationLine
                  basketLine={_}
                  onUpdateBasketItem={onUpdateBasketItem}
                />
              );
            })}
        </div>
        <div className={styles["basket-button-container"]}>
          <Button
            text="Add another item"
            classes={{
              root: styles["add-icecream-button"],
            }}
            onClick={() => {
              scrollTop();
              navigate("/categories");
            }}
          />
          <Button
            text="Go to basket"
            classes={{
              root: styles["proceed-payment-button"],
            }}
            onClick={() => {
              setShowModal(true);
            }}
          />
        </div>
      </div>

      <AllergiesModal
        isModalOpen={showModal}
        notes={notes}
        setNotes={(e) => setNotes(e.target.value)} 
        onContinue={handleContinue}
        handleCloseAllergiesModal={() => setShowModal(false)}
      />
    </>
  );
};

export default BasketConfirmation;
