import React from "react";

import styles from "./ChildCategory.module.scss";


const ChildCategory=({
    data,
    selectedId,
    onClick
                     }) => {

    return (<div className={styles["list-wrapper"]}><ul className={styles['list']}>
        {data.map((child)=>{
            return (<li key={child.id} className={`${styles['list-item']} ${child.id === selectedId ? styles["list-item-selected"] : ""} ${child.isActive !== true ? styles["list-item-disabled"] : ""}`} onClick={() => {
                if (child.isActive !== true) {
                    return;
                }
                onClick(child)
            }}>
                <div className={styles['list-item-image']}>
                    <img src={child.imagePath} alt={child.imagePath} />
                </div>
                <div className={styles['list-item-content']}>
                    <div className={`small-text ${styles['list-item-header']}`}>
                        {child.name}
                    </div>
                </div>
            </li>)
        })}
    </ul></div>);    
}

export default ChildCategory;