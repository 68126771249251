import React from 'react';

import styles from './button-increment.module.scss';

export interface IButtonIncDecProps {
  disabled?: boolean;
  onIncrement?: (e?: any) => void;
  onDecrement?: (e?: any) => void;

  classes: {
    root: string
  };
  value: string;
}

export const ButtonIncDec: React.FC<IButtonIncDecProps> = ({
  disabled = false,
  onIncrement = undefined,
  onDecrement = undefined,
  value = 1,
  classes: {
    root
  },
}) => {
  return (
    <div className={`${styles['container']} ${root}`}>
      <button type='button' disabled={disabled} onClick={onDecrement} className={"large-text"}>
        -
      </button>
      <span className='large-text'>{value || value > 0 ? value : 1}</span>
      <button type='button' disabled={disabled} onClick={onIncrement} className={"large-text"}>
        +
      </button>
    </div>
  )
}

export default ButtonIncDec; 