import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

const Loading = (isLoading) => {
    return (<div style={{display: 'flex', justifyContent: 'center'}}><CircularProgress sx={{
        color: `#cba8d2`,
            "& .MuiLinearProgress-bar": {
            color: `#cba8d2`
        }
    }} /></div>);
}

export default Loading;