import React, { useRef } from 'react'

import Button from '../../../reusable/components/Button/button';
import SectionHeading from '../../../reusable/components/SectionHeading/section-heading';
import styles from './cone.module.scss'
import ConeList from './ConeList/cone-list';
import ScoopList from './ScoopList/scoop-list';

const Cone = ({
    getName = undefined,
    scoopList = [],
    selectedScoopId = null,
    coneList = [],
    selectedConeId = null,
    scrollTop = undefined,
    handleStep = undefined,
    setStep = undefined,
    handleSelectCone = undefined,
    handleSelectScoop = undefined, 
    coneSelectionText = ''
}) => {
    const scoopRef = useRef(null)
    const btnRef = useRef(null)
    const scrollScoopBox = () => scoopRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    const scrollBtnBox = () => btnRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })

    const handleSelectConeBox = (id: number, coneSelectionText:string) => {
        handleSelectCone(id, coneSelectionText)

        setTimeout(() => {
            scrollScoopBox()
        }, 300);
    }

    const handleSelectScoopBox = (id: number) => {
        handleSelectScoop(id)

        setTimeout(() => {
            scrollBtnBox()
        }, 300);
    }

    return (
        <>
            <div className={styles['section-header']}>
                <SectionHeading header="Choose your cone or tub" />
            </div>
            <ConeList data={coneList} selectedId={selectedConeId} onClick={handleSelectConeBox} />

            {selectedConeId ?
                <div className={styles['scoop-list-container']}>
                    <div className={styles['section-header']} ref={scoopRef}>
                        <SectionHeading header={coneSelectionText} />
                    </div>
                    <ScoopList data={scoopList} selectedId={selectedScoopId} onClick={handleSelectScoopBox} />
                </div>
                : null}

            {selectedScoopId ?
                <div className={styles['next-steps-container']}>
                    <h4 className={`heading-text`}>
                        You have selected:
                    </h4>
                    <p className={`body-text`}>1x {getName(scoopList, selectedScoopId)} {getName(coneList, selectedConeId)}</p>
                    <div className={styles['button-container']} ref={btnRef}>
                        <Button
                            text="Choose Flavours"
                            classes={{
                                root: styles['choose-button']
                            }}
                            onClick={() => {
                                scrollTop();
                                handleStep(1)
                                setStep('flavours')
                            }}
                        />
                    </div>
                </div>
                : null}
        </>
    )
}

export default Cone