import { Modal } from "@mui/material";
import React from "react";

import Button from "../Button/button";
import styles from "./ValidationModal.module.scss"

const ValidationModel = ({
    isModalOpen = true,
    maxFlavours = 0,
    handleCloseValidationModal = undefined,
    type=undefined,
    secondaryType=undefined
}) => {
    const typeDescriptor=type?.toLowerCase() || 'flavours';
    const secondaryTypeDescriptor=secondaryType?.toLowerCase() || 'flavours';

    return (<Modal open={isModalOpen}>
        <div className={styles["modal"]}>
            You can only add {maxFlavours} {typeDescriptor}, please remove one of your other {secondaryTypeDescriptor} before proceeding.
            <div className={styles["btn-container"]}>
                <Button
                    text="OK"
                    classes={{
                        root: styles['close-button']
                    }}
                    onClick={handleCloseValidationModal}
                />
            </div>

        </div>
    </Modal>);
}

export default ValidationModel;