import { Modal } from "@mui/material";
import React from "react";

import Button from "../../../../reusable/components/Button/button";
import styles from "./FlavourValidationModal.module.scss"

const FlavourValidationModal = ({
    isModalOpen = true,
    maxFlavours = 0,
    handleCloseValidationModal = undefined
}) => {


    return (<Modal open={isModalOpen}>
        <div className={styles["modal"]}>
            You can only add {maxFlavours} flavours, please remove one of your other flavours before proceeding.
            <div className={styles["btn-container"]}>
                <Button
                    text="OK"
                    classes={{
                        root: styles['close-button']
                    }}
                    onClick={handleCloseValidationModal}
                />
            </div>

        </div>
    </Modal>);
}

export default FlavourValidationModal;