import React from 'react';

interface ISectionSuccessArrowProps {
    fillColor?: string;
}

const SectionSuccessArrow: React.FC<ISectionSuccessArrowProps> = ({
    fillColor = "#DF668A"
}) => {
    return (
        <svg width="147" height="144" viewBox="0 0 147 144" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M79.0499 92.4813L76.5491 91.4853C75.5643 91.0947 74.9208 90.146 74.9208 89.0899V0H72.0831V89.0942C72.0831 90.1502 71.4395 91.0947 70.4547 91.4896L67.9539 92.4856C67.9539 92.4856 67.9453 92.4942 67.9409 92.4942C62.1446 94.6835 57.9895 100.187 57.834 106.674C57.834 106.892 58.0068 107.06 58.2227 107.06C58.4387 107.06 58.6114 106.888 58.6114 106.678C58.7497 101.213 61.8638 96.4822 66.4033 94.031C69.0553 92.5972 72.3984 94.3358 72.5625 97.3322C72.5668 97.4224 72.5711 97.5125 72.5711 97.607C72.5711 100.376 71.9232 101.217 71.8585 102.325C71.8066 103.239 72.4718 104.076 73.3918 104.141C74.3593 104.209 75.167 103.445 75.167 102.497C75.167 101.299 74.45 100.513 74.45 97.607C74.45 95.0012 76.4627 92.859 79.0327 92.653C79.1277 92.6444 79.1493 92.5199 79.0586 92.4813" fill={fillColor} />
            <path d="M74.3381 126.489C74.3381 129.31 75.0335 130.07 75.0335 131.233C75.0335 132.117 74.3121 132.83 73.4267 132.83C72.5413 132.83 71.82 132.113 71.82 131.233C71.82 130.07 72.5154 129.31 72.5154 126.489C72.5154 123.823 70.3514 121.664 67.6692 121.664H63.4839L72.1741 143.154C72.6277 144.279 74.2258 144.279 74.6793 143.154L83.3652 121.664H79.1799C76.4977 121.664 74.3337 123.823 74.3337 126.489H74.3381Z" fill={fillColor} />
            <path d="M8.86302 121.668H0V138.02H8.86302C14.2707 138.02 17.4755 134.835 17.4755 129.855C17.4755 124.875 14.2707 121.668 8.86302 121.668ZM8.11148 133.294H5.75751V126.399H8.11148C10.1631 126.399 11.7914 127.618 11.7914 129.859C11.7914 132.1 10.1631 133.294 8.11148 133.294Z" fill={fillColor} />
            <path d="M39.5594 133.761C38.6567 133.761 37.5294 133.538 36.631 132.59C39.6587 132.59 42.5397 131.272 42.5397 127.563C42.5397 124.304 40.3369 121.664 36.3027 121.664H24.71V138.016H30.4675V133.16C33.7976 137.02 36.0522 138.488 39.1793 138.488C41.7104 138.488 43.5374 137.445 44.2112 136.646L42.1596 132.89C41.8097 133.264 40.8595 133.761 39.5551 133.761H39.5594ZM35.1711 129.155H30.4631V126.167H35.1711C36.1991 126.167 36.7994 126.863 36.7994 127.661C36.7994 128.46 36.1991 129.155 35.1711 129.155Z" fill={fillColor} />
            <path d="M57.1345 121.668H51.377V138.02H57.1345V121.668Z" fill={fillColor} />
            <path d="M89.7144 138.02H105.242V133.289H95.4719V131.748H103.739V127.837H95.4719V126.395H105.242V121.668H89.7144V138.02Z" fill={fillColor} />
            <path d="M123.387 121.668H117.629V138.02H123.387V121.668Z" fill={fillColor} />
            <path d="M141.243 121.668V129.408L134.807 121.668H129.896V138.02H135.658V130.452L142.093 138.02H147V121.668H141.243Z" fill={fillColor} />
        </svg>
    );
};

export default SectionSuccessArrow;

