import ShopService from '../services/ShopService';

const AuthenticatedService = {
    isOpen: false,
    authenticate(callBack) {
        ShopService.isOpen().then((response) => {
            this.isOpen = response.data.isOpen;
            callBack();
        });
    }
};

export default AuthenticatedService;
