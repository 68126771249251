/* eslint-disable no-console */
import React, { useEffect } from "react";

import styles from "./basket-popup.module.scss"

const BasketPopup = ({ data, setPopupContent }) => {
    useEffect(() => {
        const timeId = setTimeout(() => {
            setPopupContent(null)
        }, 3000)

        return () => {
            clearTimeout(timeId)
        }
    }, [data]);

    return (
        <div className={styles["basket-popup"]}>
            <p className='title_text_3'>Added to basket!</p>
            <li className={`${styles['item']}`}>
                <div className={styles['item-image']}>
                    <img src={data.image} alt={data.imagePath} />
                </div>
                <div className={styles['item-content']}>
                    <h4 className={`small-text ${styles['item-header']}`}>
                        {+data.count} x {data.name}
                    </h4>
                    <div className={`small-text ${styles['item-price']}`}>
                        £{(+data.count * +data.price).toFixed(2)}
                    </div>
                </div>
            </li>
        </div>
    )
}

export default BasketPopup;