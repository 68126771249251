import React from 'react';

interface IArrowRightProps {
    strokeColor?: string;
}

const ArrowRight: React.FC<IArrowRightProps> = ({
    strokeColor = "#29373C"
}) => {
    return (
        <svg viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1C4.35256 3.59683 8.73077 5.85714 12 8.65079C8.33333 10.5619 4.76923 12.7206 1.32692 15" stroke={strokeColor} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default ArrowRight;

