import React, { useState } from "react";

import AppRouter from "./app.router";
import BasketProvider from "./reusable/context/BasketProvider";

export const WaitModalContext = React.createContext([]);

type SavedLocationContextProps = {
  locationSaved: string | number | null;
  setLocationSaved: (value: string | number | null) => void;
};

export const SavedLocationContext =
  React.createContext<SavedLocationContextProps>({
    locationSaved: null,
    setLocationSaved: () => {},
  });
const App: React.FC = () => {
  const [locationSaved, setLocationSaved] = useState<string | number | null>(
    null
  );
  const [waitTimeModalOpen, setWaitTimeModalOpen] = useState(false);

  return (
    <div className="App">
      <BasketProvider>
        <SavedLocationContext.Provider
          value={{ locationSaved, setLocationSaved }}
        >
          <WaitModalContext.Provider
            value={[waitTimeModalOpen, setWaitTimeModalOpen]}
          >
            <AppRouter />
          </WaitModalContext.Provider>
        </SavedLocationContext.Provider>
      </BasketProvider>
    </div>
  );
};

export default App;
