import React from 'react';

interface ICloseProps {
    fillColor?: string;
}

const Close: React.FC<ICloseProps> = ({
    fillColor = "#788589"
}) => {
    return (
        <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.20423 0.758933C2.2265 0.772867 2.24751 0.789333 2.26596 0.8077L5.84169 4.2714L9.66055 0.358033C9.85595 0.1579 10.1786 0.152833 10.3798 0.3479C10.397 0.363733 10.4129 0.381467 10.4269 0.3992L10.8635 0.955267C11.0226 1.1592 11.0035 1.44927 10.8177 1.62977L6.97334 5.36643L10.8889 9.15883C11.0901 9.3539 11.0945 9.675 10.8985 9.87513C10.8775 9.89667 10.8539 9.9163 10.8298 9.93403L10.2404 10.3539C10.0354 10.4996 9.75475 10.4743 9.57844 10.295L5.8366 6.47033L1.87517 10.321C1.67595 10.5148 1.35644 10.5129 1.15977 10.3166L0.896904 10.055C0.699596 9.8593 0.697687 9.54263 0.891812 9.3444L4.76413 5.37467L1.44045 1.97683C1.27624 1.809 1.24951 1.5506 1.3749 1.353L1.67722 0.877367C1.78988 0.700034 2.02601 0.646833 2.20423 0.758933Z" fill={fillColor} />
        </svg>
    );
};

export default Close;

