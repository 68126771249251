import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { WaitModalContext } from "../../../app/app.index";
import Clock from "../../../assets/images/clock.svg";
import ArrowRight from "../../reusable/components/icons/ArrowRight/arrow-right";
import Layout from "../../layout/layout";
import SectionArrow from "../../reusable/components/icons/SectionArrow/section-arrow";
import Loading from "../../reusable/components/Loading/Loading";
import WaitTimeModal from "../../reusable/components/WaitTimeModal/WaitTimeModal";
import ShopService from "../../services/ShopService";
import styles from "./categories.module.scss";
import { Box } from "@mui/material";

function Categories() {
  const [categories, setCategories] = useState([]);
  const [waitTimes, setWaitTimes] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [waitTimeModalOpen, setWaitTimeModalOpen] =
    useContext(WaitModalContext);

  const loadCategories = () => {
    ShopService.getCategories().then((response) => {
      setCategories(response.data);
      setIsLoading(false);
    });
  };

  const loadAllWaitingTimes = async () => {
    await ShopService.allWaitingTimes().then((response) => {
      setWaitTimes(response.data);
    });
  };

  useEffect(() => {
    loadCategories();
    loadAllWaitingTimes();
  }, []);

  const closeWaitTimeModal = () => {
    setWaitTimeModalOpen(false);
  };

  return (
    <Layout backLink={"/"}>
      <div className={styles["container"]}>
        <div
          className={styles["wait-time"]}
          onClick={() => setWaitTimeModalOpen(true)}
        >
          <img src={Clock} alt="clock image" />
          <span> View current wait times</span>
          <Box sx={{ width: 10, height: 13 }}>
            <ArrowRight strokeColor="#fff" />
          </Box>
        </div>
        <div className={styles["section-top"]}>
          <SectionArrow fillColor={"#8FCAE7"} />
          <h4 className="large-text">What would you like?</h4>
        </div>
        <div className={styles["categories"]}>
          {isLoading ? (
            <Loading isLoading />
          ) : (
            <ul className={styles["list"]}>
              {categories.map((listItem) => (
                <li
                  key={listItem.id}
                  className={`${styles["list-item"]} ${styles["category-option"]}`}
                  style={{
                    backgroundImage: `url(${listItem.backgroundImage})`,
                    width: listItem.buttonWidth,
                  }}
                >
                  <Link
                    to={listItem.navigationPath}
                    className={styles["list-link"]}
                  >
                    <div className={styles["list-item-image"]}>
                      <img src={listItem.imagePath} alt={listItem.imagePath} />
                    </div>
                    <div className={`heading-text ${styles["list-item-text"]}`}>
                      {listItem.name}
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <WaitTimeModal
        isModalOpen={waitTimeModalOpen}
        waitTimes={waitTimes}
        handleCloseWaitTimeModal={() => {
          closeWaitTimeModal();
        }}
      />
    </Layout>
  );
}

export default Categories;
