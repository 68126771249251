import React from 'react';

interface IWarningProps {
    fillColor?: string;
}

const Warning: React.FC<IWarningProps> = ({
    fillColor = "#8882A2"
}) => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.99782 14C3.13831 14 0 10.8573 0 6.99782C0 3.13831 3.13831 0 6.99782 0C10.8573 0 13.9956 3.13831 13.9956 6.99782C13.9956 10.8573 10.8573 14 6.99782 14ZM6.99782 0.917889C3.64533 0.917889 0.91789 3.64533 0.91789 6.99782C0.91789 10.3503 3.64533 13.0777 7.00219 13.0777C10.3547 13.0777 13.0821 10.3503 13.0821 6.99782C13.0821 3.64533 10.3547 0.917889 6.99782 0.917889Z" fill={fillColor} />
            <path d="M7.03711 8.40943H7.00214C6.7224 8.40943 6.49512 8.18215 6.49512 7.90241V3.83747C6.49512 3.55773 6.7224 3.33044 7.00214 3.33044H7.03711C7.31685 3.33044 7.54413 3.55773 7.54413 3.83747V7.90241C7.54413 8.18215 7.31685 8.40943 7.03711 8.40943Z" fill={fillColor} />
            <path d="M6.99744 10.6692C7.45851 10.6692 7.83228 10.2954 7.83228 9.83435C7.83228 9.37328 7.45851 8.99951 6.99744 8.99951C6.53637 8.99951 6.1626 9.37328 6.1626 9.83435C6.1626 10.2954 6.53637 10.6692 6.99744 10.6692Z" fill={fillColor} />
        </svg>
    );
};

export default Warning;

