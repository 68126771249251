import React from 'react';

import styles from './section-heading.module.scss';

export interface ISectionHeading {
  header: string;
  description?: string;
  className?: string
}

export const SectionHeading: React.FC<ISectionHeading> = ({
  header = '',
  description = '',
  className = '',
}) => {
  return (
    <div className={`${styles['container']} ${className}`}>
      <p className='title_text_1'>{header}</p>
      {description ? <p className={`body-text ${styles['description']}`}>{description}</p> : null}
    </div>
  )
}

export default SectionHeading; 