import React from 'react'

import ExclusiveMark from '../../../../../assets/images/exclusive.svg'
import NewMark from '../../../../../assets/images/new.svg'
import ButtonIncDec from '../../../../reusable/components/ButtonIncDec/button-increment';
import styles from './flavour-list.module.scss'

export interface IFlavourListProps {
    data: Array<any>;
    onClick: (id: number) => void
    onIncDec: (id: number, condition: "decrement" | 'increment') => void;
    disabled?: boolean
}

export const FlavourList: React.FC<IFlavourListProps> = ({
    data = [],
    onClick = undefined,
    onIncDec = undefined,
    disabled = false
}) => {
    return (
        <ul className={styles['list']}>
            {data.map((listItem) => (
                <li
                    key={listItem.id}
                    className={`${styles['list-item-container']}`}
                >
                    {listItem.isNew ? <img className={styles['new-mark']} src={NewMark} alt="new mark" /> : null}
                    {listItem.isExclusive ? <img className={styles['exclusive-mark']} src={ExclusiveMark} alt="exclusive mark" /> : null}
                    <div
                        className={`${styles['list-item']} ${listItem.selected ? styles["list-item-selected"] : ""}`}
                        onClick={() => {
                            if (listItem.count >= 1) {
                                onIncDec(listItem.id, 'increment')
                            }

                            onClick(listItem.id)
                        }}
                    >
                        <div className={styles['list-item-image']}>
                            <img src={listItem.image} alt={listItem.image} />
                        </div>
                        <div className={styles['list-item-content']}>
                            <div className={`small-text ${styles['list-item-header']}`}>
                                {listItem.name}
                            </div>
                        </div>
                    </div>
                    <div className={styles['increment-container']}>
                        {listItem.selected ?
                            <ButtonIncDec
                                value={listItem.count}
                                onIncrement={(e) => {
                                    e.stopPropagation()
                                    onIncDec(listItem.id, 'increment')
                                }}
                                onDecrement={(e) => {
                                    e.stopPropagation()
                                    onIncDec(listItem.id, 'decrement')
                                }}
                                disabled={disabled}
                                classes={{
                                    root: styles['increment-button']
                                }}
                            />
                            : null}
                    </div>
                </li>
            ))
            }
        </ul >
    )
}

export default FlavourList