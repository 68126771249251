import React, { useContext, useEffect, useState } from 'react'

import Layout from '../../layout/layout';
import Stepper from '../../reusable/components/Stepper/stepper';
import BasketCtx from "../../reusable/context/BasketContext";
import ShopService from "../../services/ShopService";
import Cone from './Cone/cone';
import Extras from './Extras/extras';
import Flavours from './Flavours/flavours';
import styles from './ice-cream.module.scss'
import Basket from './IceCreamBasket/basket';

function IceCream() {
    const [coneList, setConeList] = useState([])
    const [scoopList, setScoopList] = useState([])
    const [flavourList, setFlavourList] = useState([])
    const [sauceList, setSauceList] = useState([])
    const [toppingList, setToppingList] = useState([])

    const [maxFlavourSelection, setMaxFlavourSelection] = useState(2);
    const [selectedConeId, setSelectedConeId] = useState(null)
    const [selectedScoopId, setSelectedScoopId] = useState(null)
    const [step, setStep] = useState('first')
    const [selectedTabId, setSelectedTabId] = useState(0)
    const [orderedProductCount, setOrderedProductCount] = useState(1)
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({
        0: true
    });

    const [showModalValidationWindow, setShowModalValidationWindow] = useState(false);

    const [coneSelectionText, setConeSelectionText] = useState('How many scoops?');

    const basketContext = useContext(BasketCtx);

    useEffect(() => {
        getCones();
        getToppings();
        getSauces();
    }, []);

    const getCones = () => {
        ShopService.getCones().then((response) => {
            setConeList(response.data);
        });
    }

    const getToppings = () => {
        ShopService.getToppings().then((response) => {
            setToppingList(response.data);
        });
    }

    const getSauces = () => {
        ShopService.getSauces().then((response) => {
            setSauceList(response.data);
        });
    }

    const prepareCones = (cones) => {
        return cones.map((cone) => {
            switch (cone.tag) {
                case "quadtriple-scoop":
                    cone.colors = ['#CBA8D2', '#FFB7AE ', '#BDE18A', '#E27EA6'];
                    break;
                case "triple-scoop":
                    cone.colors = ['#CBA8D2', '#FFB7AE ', '#BDE18A'];
                    break;
                case "double-scoop":
                    cone.colors = ['#CBA8D2', '#FFB7AE'];
                    break;
                default:
                    cone.colors = ['#CBA8D2'];
                    break;
            }

            return cone;
        })
    }

    const handleSelectCone = (id: number, selectionText: string) => {
        setSelectedConeId(id);
        setConeSelectionText(selectionText);
        ShopService.getScoops(id).then((response) => {
            let returnedCones = response.data;

            returnedCones = prepareCones(returnedCones);

            setScoopList(returnedCones);
        });
    }

    const handleSelectScoop = (id: number) => {
        setSelectedScoopId(id);
        const scoop = scoopList.filter(scoop => scoop.id == id);

        if (scoop && scoop.length > 0)
            setMaxFlavourSelection(scoop[0].maxOptionValues);

        ShopService.getFlavours(selectedConeId, id).then((response) => {
            setFlavourList(response.data);
        })
    }

    const handleSelectSauce = (id: number) => {
        const clonedData = [...sauceList]
        const newData = clonedData.map(item => {
            if (item.id === id) {
                item.selected = !item.selected

                return item
            }

            return item
        })

        setSauceList(newData)
    }

    const handleSelectTopping = (id: number) => {
        const clonedData = [...toppingList]
        const newData = clonedData.map(item => {
            if (item.id === id) {
                item.selected = !item.selected

                return item
            }

            return item
        })

        setToppingList(newData)
    }

    const checkMaxRestriction = () => {
        const clonedData = [...flavourList];
        const selectedItems = clonedData.filter(scoops => scoops.selected);
        const numberOfSelectedItems = selectedItems.reduce((previousValue, currentValue) => {
            return previousValue + currentValue.count
        }, 0);

        const proposedNumberOfFlavours = numberOfSelectedItems + 1
        return proposedNumberOfFlavours > maxFlavourSelection;
    }

    const handleSelectFlavour = (id: number) => {
        if (checkMaxRestriction()) {
            setShowModalValidationWindow(true);
            return;
        }

        const clonedData = [...flavourList]
        const newData = clonedData.map(item => {
            if (item.id === id) {
                item.selected = true

                if (item.count < 1)
                    item.count = 1;

                return item
            }

            return item
        })

        setFlavourList(newData)
    }

    const handleIncDec = (id: number, condition: "decrement" | 'increment') => {
        if (condition === 'increment') {
            if (checkMaxRestriction()) {
                setShowModalValidationWindow(true);
                return;
            }
        }

        const clonedData = [...flavourList]
        const newData = clonedData.map(item => {
            if (item.id === id) {
                if (condition === 'decrement') {
                    item.selected = Boolean(item.count - 1)
                    item.count = item.count - 1 >= 1 ? item.count - 1 : 0
                } else {
                    item.count += 1
                }
            }

            return item
        })

        setFlavourList(newData)
    }

    const handleIncDecOrderProduct = (condition: "decrement" | 'increment', product) => {
        let count = orderedProductCount
        if (condition === 'decrement') {
            count = count - 1 >= 1 ? count - 1 : 1
        } else {
            count += 1

            if (count > maxFlavourSelection)
                count = maxFlavourSelection
        }

        basketContext.updateBasketLine({
            Id: product.id,
            Qty: count
        });

        setOrderedProductCount(count)
    }

    const getTotalPrice = () => {
        const scoopPrice = scoopList.find(el => el.id === selectedScoopId)?.unitCost
        const saucePrice = sauceList.filter(el => el.selected).reduce((sum, item) => sum + +item.price, 0)
        const toppingPrice = toppingList.filter(el => el.selected).reduce((sum, item) => sum + +item.price, 0)

        return (orderedProductCount * +(+scoopPrice + +saucePrice + +toppingPrice)).toFixed(2)
    }

    const getName = (arr: Array<any>, id: number) => arr.find(el => el.id === id)?.name || ""

    const resetProduct = () => {
        setCompleted({
            0: true
        })

        setSelectedConeId(null)
        setSelectedScoopId(null)
        const newFlavourList = flavourList.map(el => {
            if (el.selected) {
                el.selected = false
                el.count = 1
                return el
            }
            return el
        })
        const newSauceList = sauceList.map(el => {
            if (el.selected) {
                el.selected = false
                return el
            }
            return el
        })
        const newToppingList = toppingList.map(el => {
            if (el.selected) {
                el.selected = false
                return el
            }
            return el
        })
        setFlavourList(newFlavourList)
        setSauceList(newSauceList)
        setToppingList(newToppingList)

        setSelectedTabId(0)
        setOrderedProductCount(1)
        setActiveStep(0)
    }

    const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

    const handleStep = (index: number) => {
        const newCompleted = { ...completed };
        newCompleted[index] = true;
        setCompleted(newCompleted);
        setActiveStep(index);

        const stepPage = index === 0 ? 'cone' : index === 1 ? 'flavours' : index === 2 ? 'extras' : ''
        setStep(stepPage)
    }

    const addToBasket = () => {
        const basketChildren = [];

        basketChildren.push(...flavourList.filter(el => el.selected));
        basketChildren.push(...sauceList.filter(el => el.selected));
        basketChildren.push(...toppingList.filter(el => el.selected));

        const basketObject = {
            productId: selectedScoopId,
            Count: 1,
            Image: coneList.find(el => el.id === selectedConeId)?.imagePath,
            name: getName(scoopList, selectedScoopId) + ' ' + getName(coneList, selectedConeId),
            price: getTotalPrice(),
            children: basketChildren
        }

        basketContext.addToBasket(basketObject);
    }

    const handleCloseValidationModal = () => {
        setShowModalValidationWindow(false);
    }

    const renderContent = () => {
        let content = null

        switch (step) {
            case 'flavours':
                content = <Flavours
                    getName={getName}
                    flavourList={flavourList}
                    selectedTabId={selectedTabId}
                    setSelectedTabId={setSelectedTabId}
                    handleSelectFlavour={handleSelectFlavour}
                    handleIncDec={handleIncDec}
                    scoopList={scoopList}
                    selectedScoopId={selectedScoopId}
                    coneList={coneList}
                    selectedConeId={selectedConeId}
                    scrollTop={scrollTop}
                    handleStep={handleStep}
                    setStep={setStep}
                    maxFlavourSelection={maxFlavourSelection}
                    handleCloseValidationModal={handleCloseValidationModal}
                    showValidationModalWindow={showModalValidationWindow}
                />
                break;
            case 'extras':
                content = <Extras
                    getName={getName}
                    flavourList={flavourList}
                    scoopList={scoopList}
                    selectedScoopId={selectedScoopId}
                    coneList={coneList}
                    selectedConeId={selectedConeId}
                    scrollTop={scrollTop}
                    setStep={setStep}
                    sauceList={sauceList}
                    handleSelectSauce={handleSelectSauce}
                    toppingList={toppingList}
                    handleSelectTopping={handleSelectTopping}
                    addItemToBasket={addToBasket}
                />
                break;
            case 'basket':
                content = <Basket
                    scrollTop={scrollTop}
                    handleIncDecOrderProduct={handleIncDecOrderProduct}
                    resetProduct={resetProduct}
                />
                break;
            default:
                content = <Cone
                    getName={getName}
                    scoopList={scoopList}
                    selectedScoopId={selectedScoopId}
                    coneList={coneList}
                    selectedConeId={selectedConeId}
                    scrollTop={scrollTop}
                    handleStep={handleStep}
                    setStep={setStep}
                    handleSelectCone={handleSelectCone}
                    handleSelectScoop={handleSelectScoop}
                    coneSelectionText={coneSelectionText}
                />
                break;
        }

        return content
    }

    return (
        <Layout>
            <div className={styles['container']}>
                <div className={styles['content']}>
                    {step !== 'basket' ?
                        <Stepper
                            steps={[1, 2, 3]}
                            activeStep={activeStep}
                            completed={completed}
                            onClick={handleStep}
                            classes={{ root: styles.stepper }}
                        /> : null}
                    {renderContent()}
                </div>
            </div>
        </Layout>
    )
}

export default IceCream