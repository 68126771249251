/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from "react-router";

import ExclusiveMark from '../../../../assets/images/exclusive.svg'
import NewMark from "../../../../assets/images/new.svg";
import Layout from "../../../layout/layout";
import BasketPopup from '../../../reusable/components/BasketPopup/basket-popup';
import SectionHeading from "../../../reusable/components/SectionHeading/section-heading";
import BasketCtx from "../../../reusable/context/BasketContext";
import ShopService from "../../../services/ShopService";
import styles from "./CategoryContents.module.scss";
import ProductModal from './ProductModal/product-modal';

function CategoryContents() {
    const { categoryName } = useParams();
    const basketContext = useContext(BasketCtx);
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [categoryDisplayName, setCategoryDisplayName] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [selectedSize, setSelectedSize] = useState(null);
    const [selectedFlavour, setSelectedFlavour] = useState(null);
    const [popupContent, setPopupContent] = useState(null);
    const [uniqueTags, setUniqueTags] = useState([]);

    const loadProducts = () => {
        ShopService.getBasicCategoryProducts(categoryName)
            .then((response) => {
                console.log(response.data.products);
                setProducts(response.data.products);
                setCategoryDisplayName(response.data.name);
                
                setUniqueTags([...new Set(response.data.products.map(item => item.tag))]);
            });

    }

    useEffect(() => {
        loadProducts();
    }, []);

    const handleSelectItem = (item: any) => {
        setSelectedProduct(item);
    }

    const handleClose = () => {
        if (!disabled) {
            setSelectedProduct(null)
            setSelectedSize(null)
            setSelectedFlavour(null)
        }
    }

    const handleIncDec = (id: number, condition: "decrement" | 'increment') => {
        const clonedItem = { ...selectedProduct }

        clonedItem.count = clonedItem.count > 0 ? clonedItem.count : 1
        if (condition === 'decrement') {
            clonedItem.count = clonedItem.count - 1 >= 1 ? clonedItem.count - 1 : 1
        } else {
            clonedItem.count += 1
        }

        const newData = { ...clonedItem }

        setSelectedProduct(newData);
    }

    const addToBasket = (item) => {
        setDisabled(true)

        item.selectedOptions = item.optionValues.filter((item) => item.id == selectedFlavour);
        item.selectedProducts = item.products.filter((item) => item.id == selectedSize);

        if (!item.selectedProducts.length && !item.selectedOptions.length) {
            selectedProduct.count = +item.count > 0 ? +item.count : 1;
        }

        if (item.selectedProducts.length > 0) {
            const selectedProduct = item.selectedProducts[0];
            selectedProduct.name = `${item.name} - ${selectedProduct.name}`;
            selectedProduct.count = +item.count > 0 ? +item.count : 1;
            
            item = {
                ...item,
                ...selectedProduct
            }
            item.productid=selectedProduct.id;
        }

        if (item.selectedOptions.length > 0) {
            item.children = item.selectedOptions.map((child) => {
                return {
                    optionId: child.id,
                    name: child.name,
                    count: 1
                }
            });
        }
        
        item.productId=item.id;
        item.id = null;
        
        basketContext.addToBasket(item);
        setPopupContent(item)


        setTimeout(() => {
            setDisabled(false)
            handleClose()
        }, 1000);
    }

    const handleChangeSize = (inputValue) => {
        const id = inputValue.split('-')[1]
        setSelectedSize(+id);
    }

    const handleChangeFlavour = (inputValue) => {
        const id = inputValue.split('-')[1]
        setSelectedFlavour(+id);
    }

    return (
        <Layout>
            <div className={styles['content']}>
                {!!popupContent && <BasketPopup data={popupContent} setPopupContent={setPopupContent} />}
                {/* <BackButton href={"/categories"} /> */}
                
                {uniqueTags.map((tag)=> {
                    return (<div className={`${styles[`tag-wrap`]}`}>
                        <div className={styles['section-header']}>
                            <SectionHeading header={tag} />
                        </div>
                        
                        <ul className={styles['list']}>
                            {products.filter((prod) => prod.productID == null && prod.tag==tag).map((_) => {
                                return (<li
                                    key={_.id}
                                    className={`${styles['list-item-container']}`}
                                >
                                    {_.isNew ? <img className={styles['new-mark']} src={NewMark} alt="new mark" /> : null}
                                    {_.isExclusive ? <img className={styles['exclusive-mark']} src={ExclusiveMark} alt="exclusive mark" /> : null}
                                    <div
                                        className={`${styles['list-item']} ${_.selected ? styles["list-item-selected"] : ""}`}
                                        onClick={() => {
                                            handleSelectItem(_);
                                        }}
                                    >
                                        <div className={styles['item-wrapper']}>
                                            <div className={styles['list-item-image']}>
                                                <img src={_.image} alt={_.image} />
                                            </div>
                                            <div className={styles['list-item-content']}>
                                                <div className={`small-text ${styles['list-item-header']}`}>
                                                    {_.name}
                                                </div>
                                                <div className={`title_text_3 ${styles['list-item-price']}`}>
                                                    {_.products.length > 0 ? 'from ': ''}{_.price ? '£' + _.price.toFixed(2) : '-'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>);
                            })}

                        </ul>

                    </div>);
                })}

                {selectedProduct ?
                    <ProductModal
                        data={selectedProduct}
                        onClose={handleClose}
                        onIncDec={handleIncDec}
                        addToBasket={addToBasket}
                        disabled={disabled}
                        selectedSize={selectedSize}
                        handleChangeSize={handleChangeSize}
                        selectedFlavour={selectedFlavour}
                        handleChangeFlavour={handleChangeFlavour}
                    />
                    : null}
            </div>
        </Layout>
    );
};

export default CategoryContents