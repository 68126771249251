import axios from "axios";

const getCategories = () => {
    return axios.get("/api/shop/categories");
}

const getCones = () => {
    return axios.get("/api/shop/cones");
}
const getScoops = (coneCategoryId) => {
    return axios.get("/api/shop/cones/"+coneCategoryId+"/scoops")
}

const getFlavours = (coneCategoryId, scoopProductId) =>
{
    return axios.get("/api/shop/cones/"+coneCategoryId+"/scoops/"+scoopProductId+"/flavours");
}

const getOptions = (productId) => {
    return axios.get("/api/shop/products/"+productId+"/options");
}

const getToppings=() => {
    return axios.get("/api/shop/toppings");
}

const getSauces=() => {
    return axios.get("/api/shop/sauces");
}

const getWaitingTime=() => {
    return axios.get("/api/shop/currentwaittime");
}

const isOpen=() => {
    return axios.get("/api/shop/is-open");
}

const getBasicCategoryProducts=(categoryName) => {
    return axios.get(`api/shop/category/${categoryName}/products`);
}

const getLocations = () => {
    return axios.get("api/shop/locations")
}

const getDynamicCategory=(navigationPath)=>{
    return axios.get(`api/shop/dynamic-selection/${navigationPath}`)
}

const allWaitingTimes = () => {
    return axios.get('/api/shop/all-waiting-times');
}

export default {
    getCategories,
    getCones,
    getScoops,
    getFlavours,
    getToppings,
    getSauces,
    getWaitingTime,
    getBasicCategoryProducts,
    getLocations,
    isOpen,
    getDynamicCategory,
    allWaitingTimes
}