import React from 'react'

import styles from './sauce-list.module.scss'

export interface ISauceListProps {
    data: Array<any>;
    onClick: (id: number) => void
}

export const SauceList: React.FC<ISauceListProps> = ({
    data = [],
    onClick = undefined,
}) => {
    return (
        <ul className={styles['list']}>
            {data.map((listItem) => (
                <li
                    key={listItem.id}
                    className={`${styles['list-item']} ${listItem.selected ? styles["list-item-selected"] : ""}`}
                    onClick={() => onClick(listItem.id)}
                >
                    <div className={styles['list-item-image']}>
                        <img src={listItem.image} alt={listItem.image} />
                    </div>
                    <div className={styles['list-item-content']}>
                        <div className={`small-text ${styles['list-item-header']}`}>
                            {listItem.name}
                        </div>
                        <div className={`title_text_3 ${styles['list-item-price']}`}>
                            {listItem.price ? '£' + listItem.price.toFixed(2) : '-'}
                        </div>
                    </div>
                </li>
            ))
            }
        </ul>
    )
}

export default SauceList