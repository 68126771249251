import React from "react";

import MasterCategory from "./MasterCategory/MasterCategory";


const ProductSelector=({
    masterCategorySelectionText,
    masterCategoryChildren,
    onCategorySelected,
    onProductSelected,
    selectedProductId,
    selectedCategoryId,
    handleStep,
    scrollTop,
    setStep,
    selectedCategoryName,
    selectedProductName
}) => {
    return (<div>
        <MasterCategory 
            selectionText={masterCategorySelectionText} 
            children={masterCategoryChildren}
            onCategorySelected={onCategorySelected}
            onProductSelected={onProductSelected}
            selectedProductId={selectedProductId}
            selectedCategoryId={selectedCategoryId}
            handleStep={handleStep}
            scrollTop={scrollTop}
            setStep={setStep}
            selectedCategoryName={selectedCategoryName}
            selectedProductName={selectedProductName}
        />
    </div>)
}

export default ProductSelector;