import './index.scss';
import './styles/all.scss';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './app/app.index';
import AuthenticatedService from "./app/services/AuthenticatedService";

AuthenticatedService.authenticate(() => {
    ReactDOM.render(<App/>, document.getElementById('root'));
});