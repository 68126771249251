import React from "react";

import styles from "./CurrentlySelected.module.scss";

const CurrentlySelected = ({
    selectedCategoryName = '', 
    selectedProductName = '',
    selectedOptions = [],
    selectedExtras = []
                           }) => {
    
   return (<div className={styles['next-steps-container']}>
        <h4 className={`heading-text`}>
            You have selected:
        </h4>
        <p className={`body-text`}>1x {selectedProductName} {selectedCategoryName}</p>
        
        {
            selectedOptions.map((option)=> {
                return (<p className={`body-text`} key={option.product.id}>{option.count}x {option.product.basketName}</p>)
            })
        }

        {
            selectedExtras.map((extra)=>{
                return (<p className={`body-text`} key={extra.id}>{extra.count} x {extra.name}</p>)
            })
        }
    </div>);
}

export default CurrentlySelected;