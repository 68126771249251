import React from "react";

import Button from "../../../../reusable/components/Button/button";
import SectionHeading from "../../../../reusable/components/SectionHeading/section-heading";
import CurrentlySelected from "../CurrentlySelected/CurrentlySelected";
import styles from "./ProductExtras.module.scss";
import ProductExtrasLine from "./ProductExtrasLine/ProductExtrasLine";

const ProductExtras = ({
                           associatedCategory = undefined,
                           onClick = undefined,
                            selectedProductName='', 
                            selectedCategoryName='',
                            selectedOptions=[],
                            selectedExtras=[],
                            addToBasket = undefined
                       }) => {

    const getContent=() => {
        const childCategories = associatedCategory.categories.map((subCategory)=>{
            return (<ProductExtrasLine 
                        key = {subCategory.id}
                        associatedCategory={subCategory} 
                        onClick={onClick} 
                        selectedExtras={selectedExtras} />);
        });
        
        return childCategories;
    }
    
    
    return (<>
        <div className={styles['section-header']}>
            <SectionHeading header="Add extras" description='Select any optional extras' />
        </div>
        <div className={styles["content-container"]}>
            {getContent()}
        </div>
        <div className={styles['next-steps-container']}>
            <CurrentlySelected 
                selectedProductName={selectedProductName} 
                selectedCategoryName={selectedCategoryName} 
                selectedOptions={selectedOptions}
                selectedExtras={selectedExtras.map((response)=>response.product)}
            />
            
            <div className={styles['button-container']}>
                <Button
                    text="Add to basket"
                    classes={{
                        root: styles['choose-button']
                    }}
                    onClick={addToBasket}
                />
            </div>
        </div>
    </>);
}

export default ProductExtras;