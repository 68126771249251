import { Modal } from "@mui/material";
import React from "react";

import Button from "../../../../reusable/components/Button/button";
import Close from "../../../../reusable/components/icons/Close/close";
import styles from "./remove-modal.module.scss"

const RemoveProductModal = ({
    showModal,
    onConfirm,
    handleCloseModal = undefined,
    disabled = false
}) => {
    return (
        <Modal open={showModal}>
            <div className={styles["modal"]}>
                <button disabled={disabled} type="button" className={`${styles['close-icon']}`} onClick={handleCloseModal}>
                    <Close />
                </button>
                <h1 className="heading-text">Remove Item from basket?</h1>

                <div className={styles["btn-container"]}>
                    <Button
                        text="Cancel"
                        classes={{
                            root: styles['close-button']
                        }}
                        onClick={handleCloseModal}
                        disabled={disabled}
                    />
                    <Button
                        text="Remove"
                        classes={{
                            root: styles['remove-button']
                        }}
                        onClick={onConfirm}
                        disabled={disabled}
                    />
                </div>
            </div>
        </Modal>);
}

export default RemoveProductModal