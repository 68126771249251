import React, {useRef, useState} from "react";
import {titleCase} from "title-case";

import Button from "../../../../reusable/components/Button/button";
import SectionHeading from "../../../../reusable/components/SectionHeading/section-heading";
import ValidationModal from "../../../../reusable/components/ValidationModal/ValidationModal";
import CurrentlySelected from "../CurrentlySelected/CurrentlySelected";
import ProductOptionLine from "./ProductOptionLine/ProductOptionLine";
import styles from "./ProductOptions.module.scss";

const ProductOptions=({
                          data = [],
                          onClick = undefined,
                          onIncDec = undefined,
                          disabled = false,
                          maximumSelectionsAllowed=0,
                          currentNumberSelected=0,
                          onShowRestrictionModal=undefined,
                          showRestrictionModal=false,
                          onCloseRestrictionModal=undefined,
                          selectedOptions=[],
                          selectedProductName ='',
                          selectedCategoryName='',
                          handleStep,
                          setStep,
                          scrollTop,
                          showExtendedHeader,
                          selectedCategory
                      }) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const tabs=[...new Set(data.map(item => item.tag))];

    const btnRef = useRef(null);
    
    const checkFlavourLength = () => {
        const length = currentNumberSelected;
        return length >= (maximumSelectionsAllowed ?? 0);
    }
    
    const getFlavoursToSelect = () => {
        const numberOfFlavoursSelected = currentNumberSelected;
        return maximumSelectionsAllowed - numberOfFlavoursSelected;
    }

    const scrollBtnBox = () => btnRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    
    const checkIncDec= (product, value, dir) => {
        onIncDec(product, value, dir);
        setTimeout(() => {
            const length=dir=='increment' ? currentNumberSelected+value : currentNumberSelected-value;
            if (length >= (maximumSelectionsAllowed ?? 0)) scrollBtnBox()
        }, 300);
    }
    
    const renderOptions=()=>{
        const currentTag = tabs[selectedTab];
        const dataToReturn = data.filter((line)=>line.tag==currentTag);
          
        return (<ul className={styles['list']}>
            {dataToReturn.map((listItem) => {
                return (<ProductOptionLine
                    onIncDec={checkIncDec}
                    onClick={onClick}
                    product={listItem}
                    disabled={disabled}
                    maxAllowed={maximumSelectionsAllowed}
                    currentNumberSelected={currentNumberSelected}
                    onShowRestrictionModal={onShowRestrictionModal}
                    selectedItem={selectedOptions.filter(item=>item.product.id==listItem.id).length>0}
                    key={listItem.id}
                />);
            })}
        </ul>);
    }
    
    const produceTabs = (tabs : any[]) => {
        return tabs.map((item, index) =>
            <div
                className={`body-text ${styles['tab']} ${selectedTab === index ? styles['selected-tab'] : ''}`}
                key={index}
                onClick={() => setSelectedTab(index)}
            >
                {titleCase(item.replace("-"," "))}
            </div>
        );
    }
    
    const renderTabs = () => {
        return (
            <div className={styles['tabs-container']}>
                <div className={styles['tabs']}>
                    {selectedCategory?.categoryProductTags?.length>0 ? produceTabs(selectedCategory.categoryProductTags.map(x=>x.tag))  : produceTabs(tabs)}
                </div>
            </div>)
    }
    
    return(<>
        <div className={styles['section-header']}>
            {showExtendedHeader ? 
                <SectionHeading header={"Choose your flavours"} description={`For classic shakes pick ${maximumSelectionsAllowed} of the same flavours or mix it up with ${maximumSelectionsAllowed} different flavours`} /> 
                : <SectionHeading header={"Choose your " + maximumSelectionsAllowed + " flavours"} />}
        </div>
            
        {renderTabs()}

        {renderOptions()}

        <div className={styles['next-steps-container']}>
            { currentNumberSelected>=maximumSelectionsAllowed ?
                <>
                    <CurrentlySelected 
                        selectedProductName={selectedProductName} 
                        selectedCategoryName={selectedCategoryName}
                        selectedOptions={selectedOptions}
                    />
                </>
                : <>
                    <h4 className={`heading-text`}>
                        Please select {getFlavoursToSelect()} more flavours
                    </h4>
                </>
            }
            <div className={styles['button-container']} ref={btnRef}>
                <Button
                    text="Select Extras"
                    classes={{
                        root: styles['choose-button']
                    }}
                    onClick={() => {
                        scrollTop();
                        setTimeout(() => {
                            handleStep(2)
                            setStep('extras')
                        }, 300);
                    }}
                    disabled={!checkFlavourLength()}
                />
            </div>
            <ValidationModal
                maxFlavours={maximumSelectionsAllowed}
                isModalOpen={showRestrictionModal}
                handleCloseValidationModal={onCloseRestrictionModal}
            />
        </div>
    </>);
};

export default ProductOptions;