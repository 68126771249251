import React, { useRef } from 'react'

import styles from './radio.button.module.scss'

interface IRadioInput {
    name?: string;
    label?: any;
    value?: string;
    isChecked?: boolean;
    handleChange?: (id: string) => void;
    className?: string;
    disabled?: boolean
}

const RadioInput: React.FC<IRadioInput> = ({
    name = '',
    label = '',
    value = '',
    isChecked = false,
    handleChange = undefined,
    className = '',
    disabled = false
}) => {
    const radioRef = useRef<any>();

    return (
        <div className={`${styles['container']} ${className}`}>
            <input
                type="radio"
                className={styles["custom-radio"]}
                name={name}
                id={value}
                checked={isChecked}
                onChange={(e: any) => handleChange(e.target.id)}
                ref={radioRef}
                disabled={disabled}
            />
            <span className={`${styles['mark']}`} onClick={() => radioRef.current.click()}></span>
            <label htmlFor={value}>
                {label}
            </label>
        </div>
    );
};

export default RadioInput