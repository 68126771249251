import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Layout from "../../layout/layout";
import Button from "../../reusable/components/Button/button";
import Loading from "../../reusable/components/Loading/Loading";
import SectionHeading from "../../reusable/components/SectionHeading/section-heading";
import BasketCtx from "../../reusable/context/BasketContext";
import { checkPhoneNumber } from "../../reusable/utils/check-phone-number";
import BasketService from "../../services/BasketService";
import ShopService from "../../services/ShopService";
import styles from "./basket.module.scss";
import BasketForm from "./BasketForm/basket-form";
import BasketList from "./BasketList/basket-list";
import RemoveProductModal from "./BasketList/RemoveModal/remove-modal";
import { SavedLocationContext } from "../../app.index";

function Basket() {
  const navigate = useNavigate();
  const basketContext = useContext(BasketCtx);

  const [disabled, setDisabled] = useState(false);
  const { locationSaved } = useContext(SavedLocationContext);
  const [selectedVanuNumber, setSelectedVanuNumber] = useState(null);
  const [venueNumberInvalid, setVenueNumberInvalid] = useState(false);
  const [formValues, setFormValues] = useState({
    firstName: "",
    firstNameErr: "",
    lastName: "",
    lastNameErr: "",
    phoneNumber: "",
    phoneNumberErr: "",
    notes: basketContext.notes ?? "",
  });
  const [locations, setLocations] = useState([]);
  const [showRemovePopup, setShowRemovePopup] = useState(false);
  const [removePopupId, setRemovePopupId] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const selectedLocation = locations?.filter(
      (row) => row.id == locationSaved
    )[0];
    setSelectedVanuNumber(selectedLocation);
  }, [locations, locationSaved]);

  const handleChangeField = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    if (fieldName === "phoneNumber") {
      const isValid = checkPhoneNumber(fieldValue);

      setFormValues((prev) => {
        return {
          ...prev,
          phoneNumber: fieldValue,
          phoneNumberErr: !isValid ? "Phone number is invalid" : "",
        };
      });
      return;
    }

    if (fieldName === "firstName") {
      setFormValues((prev) => {
        return {
          ...prev,
          firstNameErr: "",
        };
      });
    }

    if (fieldName === "lastName") {
      setFormValues((prev) => {
        return {
          ...prev,
          lastNameErr: "",
        };
      });
    }

    setFormValues((prev) => {
      return { ...prev, [fieldName]: fieldValue };
    });
  };

  const handleRemove = (id: number) => {
    setRemovePopupId(id);
    setShowRemovePopup(true);
  };

  const removeProduct = () => {
    setDisabled(true);

    setTimeout(() => {
      setDisabled(false);
      basketContext.deleteBasketLine(removePopupId);
      setShowRemovePopup(false);
      setRemovePopupId(null);
    }, 1500);
  };

  const handleIncDec = (id: string, condition: "decrement" | "increment") => {
    const clonedData = [...basketContext.basketLines];
    clonedData.map((item) => {
      if (item.id === id) {
        if (condition === "decrement") {
          item.count = item.count - 1 >= 1 ? item.count - 1 : 1;
        } else {
          item.count += 1;
        }

        basketContext.updateBasketLine({
          Id: item.id,
          Qty: item.count,
        });
      }

      return item;
    });

    basketContext.loadBasket();
  };

  const handleSelectVanuNumber = (id: number) => {
    setSelectedVanuNumber(id);
    setVenueNumberInvalid(false);
  };

  const renderTotalPrice = () => {
    const totalPrice = basketContext.basketLines.reduce(
      (sum, b) => sum + b.count * b.price,
      0
    );
    return totalPrice.toFixed(2) || 0;
  };

  const completeBasket = () => {
    let isInValid = false;

    if (formValues.firstName == "") {
      setFormValues((prev) => {
        return {
          ...prev,
          firstNameErr: "Please enter a first name",
        };
      });
      isInValid = true;
    }

    if (formValues.lastName == "") {
      setFormValues((prev) => {
        return {
          ...prev,
          lastNameErr: "Please enter a last name",
        };
      });
      isInValid = true;
    }

    if (formValues.phoneNumber == "") {
      setFormValues((prev) => {
        return {
          ...prev,
          phoneNumberErr: "Please enter a phone number",
        };
      });
      isInValid = true;
    }

    if (selectedVanuNumber == null) {
      isInValid = true;
      setVenueNumberInvalid(true);
    }

    if (isInValid) return;

    setIsLoading(true);

    BasketService.completeBasket({
      firstName: formValues.firstName,
      surname: formValues.lastName,
      mobileNumber: formValues.phoneNumber,
      notes: formValues.notes,
      locationId: selectedVanuNumber.id,
    }).then((response) => {
      document.location.href = response.data;
    });
  };

  const renderContent = () => {
    let content = null;

    if (basketContext.basketLines.length) {
      content = (
        <>
          <div className={styles["section-header"]}>
            <SectionHeading
              header={`Basket`}
              description="Please check items and proceed to payment"
            />
            <BasketList
              data={basketContext.basketLines}
              onIncDec={handleIncDec}
              handleRemove={handleRemove}
              disabled={disabled}
            />
            <BasketForm
              formValues={formValues}
              handleChangeField={handleChangeField}
              numList={locations}
              handleSelectVanuNumber={handleSelectVanuNumber}
              selectedVanuNumber={selectedVanuNumber}
              venueNumberInvalid={venueNumberInvalid}
            />
            <div className={styles["allergen-btn-container"]}>
              <Button
                text="Allergen Information"
                classes={{
                  root: styles["allergen-btn"],
                }}
                onClick={() =>
                  window.open(
                    "https://www.theicecreamfarm.co.uk/wp-content/uploads/2024/03/Parlour-Allergens-2024.pdf",
                    "_blank"
                  )
                }
              />
            </div>
            <div className={styles["total-container"]}>
              <div className={styles["subtotal-container"]}>
                <div className="title_text_2">Total</div>
                <div className="title_text_2">£{renderTotalPrice()}</div>
              </div>
            </div>

            <div className={styles["proceed-container"]}>
              <Button
                text="Continue shopping"
                classes={{
                  root: styles["continue-shopping-button"],
                }}
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  navigate("/categories");
                }}
              />
              <Button
                text="Proceed to Payment"
                classes={{
                  root: styles["proceed-payment-button"],
                }}
                disabled={disabled}
                onClick={completeBasket}
              />
            </div>

            {!!showRemovePopup && (
              <RemoveProductModal
                handleCloseModal={() => setShowRemovePopup(false)}
                showModal={showRemovePopup}
                onConfirm={removeProduct}
                disabled={disabled}
              />
            )}
          </div>
        </>
      );
    } else {
      content = (
        <div className={styles["add-button-container"]}>
          <Button
            text="Add a product"
            classes={{
              root: styles["add-button"],
            }}
            onClick={() => navigate("/categories", { replace: true })}
          />
        </div>
      );
    }
    return content;
  };

  const getLocations = () => {
    ShopService.getLocations().then((response) => {
      setLocations(response.data);
    });
  };

  useEffect(() => {
    getLocations();
  }, []);

  return (
    <Layout>
      <div className={styles["container"]}>
        <div className={styles["content"]}>
          {isLoading ? <Loading isLoading /> : renderContent()}
        </div>
      </div>
    </Layout>
  );
}

export default Basket;
