import React, { useRef } from 'react'

import Button from '../../../reusable/components/Button/button';
import SectionHeading from '../../../reusable/components/SectionHeading/section-heading';
import FlavourList from './FlavourList/flavour-list';
import styles from './flavours.module.scss'
import FlavourValidationModal from "./FlavourValidationModal/FlavourValidationModal";

const Flavours = ({
    getName = undefined,
    flavourList = [],
    selectedTabId = null,
    setSelectedTabId = undefined,
    handleSelectFlavour = undefined,
    handleIncDec = undefined,
    scoopList = [],
    selectedScoopId = null,
    coneList = [],
    selectedConeId = null,
    scrollTop = undefined,
    handleStep = undefined,
    setStep = undefined,
    maxFlavourSelection = undefined,
    showValidationModalWindow = false,
    handleCloseValidationModal = undefined
}) => {
    const checkFlavourLength = () => {
        const length = flavourList.filter(el => el.selected).reduce((sum, item) => sum + item.count, 0)
        return length >= (maxFlavourSelection ?? 0);
    }

    const getFlavoursToSelect = () => {
        const numberOfFlavoursSelected = flavourList.filter(el => el.selected).length;
        return maxFlavourSelection - numberOfFlavoursSelected;
    }

    const renderTabs = () => {
        return (
            <div className={styles['tabs-container']}>
                <div className={styles['tabs']}>
                    {['Ice Cream', 'Sorbets', 'Vegan'].map((item, index) =>
                        <div
                            className={`body-text ${styles['tab']} ${selectedTabId === index ? styles['selected-tab'] : ''}`}
                            key={index}
                            onClick={() => setSelectedTabId(index)}
                        >
                            {item}
                        </div>
                    )}
                </div>
            </div>
        )
    }

    const btnRef = useRef(null)
    const scrollBtnBox = () => btnRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })

    const handleSelectFlavourBox = (id: number) => {
        handleSelectFlavour(id)

        setTimeout(() => {
            if (checkFlavourLength()) scrollBtnBox()
        }, 300);
    }

    const renderTabContent = () => {
        let content = null

        switch (selectedTabId) {
            case 0:
                content = (
                    <div className={styles['flavour-container']}>
                        <FlavourList data={flavourList.filter(item => item.tag == "ice-cream")} onClick={handleSelectFlavourBox} onIncDec={handleIncDec} />
                    </div>
                )
                break;
            case 1:
                content = (
                    <div className={styles['sorbet-container']}>
                        <FlavourList data={flavourList.filter(item => item.tag == "sorbet")} onClick={handleSelectFlavourBox} onIncDec={handleIncDec} />
                    </div>
                )
                break;
            case 2:
                content = (
                    <div className={styles['vegan-container']}>
                        <FlavourList data={flavourList.filter(item => item.tag == "vegan")} onClick={handleSelectFlavourBox} onIncDec={handleIncDec} />
                    </div>
                )
                break;
            default:
                break;
        }

        return (
            <div className={styles['tabs-content']}>
                {content}
            </div>
        )
    }

    return (
        <>
            <div className={styles['section-header']}>
                <SectionHeading header={"Choose your " + maxFlavourSelection + " flavours"} />
            </div>
            {renderTabs()}
            {renderTabContent()}
            <div className={styles['next-steps-container']}>
                {checkFlavourLength() ?
                    <>
                        <h4 className={`heading-text`}>
                            You have selected:
                        </h4>
                        <p className={`body-text`}>1x {getName(scoopList, selectedScoopId)} {getName(coneList, selectedConeId)}</p>
                        {flavourList.filter(el => el.selected).map(item =>
                            <p className={`body-text`} key={item.id}>{item.count}x {item.name}</p>
                        )}
                    </>
                    : <>
                        <h4 className={`heading-text`}>
                            Please select {getFlavoursToSelect()} more flavours
                        </h4>
                    </>
                }
                <div className={styles['button-container']} ref={btnRef}>
                    <Button
                        text="Select Extras"
                        classes={{
                            root: styles['choose-button']
                        }}
                        onClick={() => {
                            scrollTop();
                            setTimeout(() => {
                                handleStep(2)
                                setStep('extras')
                            }, 300);
                        }}
                        disabled={!checkFlavourLength()}
                    />
                </div>
                <FlavourValidationModal 
                    maxFlavours={maxFlavourSelection}
                    isModalOpen={showValidationModalWindow}
                    handleCloseValidationModal={handleCloseValidationModal}
                    />
            </div>
        </>
    )
}

export default Flavours