import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import React from 'react';

import styles from './stepper.module.scss';

export interface IStepperCompProps {
  steps: Array<any>,
  completed: any,
  activeStep: number,
  onClick: (index: number) => void,
  classes?: {
    root: string
  }
}

const HorizontalNonLinearStepper: React.FC<IStepperCompProps> = ({
  steps = [],
  activeStep = 1,
  onClick = undefined,
  classes,
  completed = {}
}) => {
  // eslint-disable-next-line no-console
  console.log(completed)
  return (
    <Stepper activeStep={activeStep} className={styles['stepper-container']} classes={classes}>
      {steps.map((_, index) => (
        <Step
          key={index}
          completed={activeStep === index ? false : completed[index]}
          onClick={() => completed[index] ? onClick(index) : undefined}
          sx={{
            '&.MuiStep-root': {
              'svg': {
                color: "#F7F0DE"
              }
            },
            '&.MuiStep-root+.MuiStepConnector-root': {
              'span': {
                borderColor: "#F7F0DE",
                borderTopWidth: 2
              }
            },
            '& .MuiStepLabel-root .Mui-completed': {
              color: '#FFB7AE',
            },
            '& .MuiStepLabel-root .Mui-active': {
              color: '#FFB7AE',
            },
            '&.MuiStep-root+.MuiStepConnector-root.Mui-active': {
              'span': {
                borderColor: "#FFB7AE"
              }
            },
            '&.MuiStep-root+.MuiStepConnector-root.Mui-completed': {
              'span': {
                borderColor: "#FFB7AE"
              }
            },
            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
              fontFamily: 'HoneyLemon-Regular',
              fontSize: 17,
            },
          }}
        >
          <StepButton disableRipple color="inherit"></StepButton>
        </Step>
      ))
      }
    </Stepper >
  );
}

export default HorizontalNonLinearStepper