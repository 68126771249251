import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import Basket from '../../reusable/components/icons/Basket/basket'
import Logo from '../../reusable/components/icons/Logo/logo';
import BasketCtx from "../../reusable/context/BasketContext";
import ArrowLeft from './../../reusable/components/icons/ArrowLeft/arrow-left';
import styles from './header.module.scss'

const Header = ({backLink}) => {
    const basketCtx = useContext(BasketCtx);

    const getBasketTotalItems = () => {
        if (basketCtx.basketLines) {
            const numberOfItems = basketCtx.basketLines.reduce(((previousValue, currentValue) => {
                return previousValue + (currentValue.count == 0 ? 1 : currentValue.count);
            }), 0);

            return numberOfItems;
        }
        return 0;
    }

    const navigate = useNavigate();

    const handleBack = () => {
        if (!backLink)
        {
            navigate("/categories")
        }    
        else 
            navigate(backLink)
    };

    return (
        <div className={styles['container']}>
            <div className={styles['content']}>
                <div className={styles['back']} onClick={handleBack}>
                    <ArrowLeft strokeColor="white" />
                    <p className='body-text'>Back</p>
                </div>
                <Link to="/" className={styles['main-logo']}>
                    <Logo fillColor="#ffffff" />
                </Link>
                <Link to="/basket">
                    <div className={styles['basket']}>
                        <span>{getBasketTotalItems()}</span>
                        <Basket />
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Header