import React, { useEffect, useState } from "react";

import BasketService from "../../services/BasketService";
import BasketCtx from "./BasketContext";
import BasketContextInterface from "./BasketContextInterface";

export default function BasketProvider({ children }) {
  const [state, setState] = useState({
    basketLines: {
      lines: [],
    },
    lastCreatedId: "",
    notes: "",
  });

  const updateState = (newState = {}) => {
    setState((_state) => ({
      ..._state,
      ...newState,
    }));
  };

  const updateBasket = async (basketData) => {
    updateState({
      basketLines: basketData,
    });
  };

  const loadBasket = () => {
    BasketService.getBasketLines().then(async (response) => {
      await updateBasket(response.data);
    });
  };

  useEffect(() => {
    loadBasket();
  }, []);

  const addToBasket = async (basketLine) => {
    await BasketService.createBasket(basketLine).then(async (response) => {
      updateBasket(response.data);
      updateState({
        lastCreatedId: response.data.lastCreatedId,
      });
    });
  };

  const updateBasketLine = (basketLine) => {
    BasketService.updateBasketLine(basketLine).then((response) => {
      updateBasket(response.data);
    });
  };

  const updateBasketLineForProduct = (basketLine) => {
    BasketService.updateBasketLineForProduct(basketLine).then((response) => {
      updateBasket(response.data);
    });
  };

  const deleteBasketLine = (id) => {
    BasketService.deleteBasketLine(id).finally(() => {
      loadBasket();
    });
  };

  const clearBasket = () => {
    BasketService.clearBasket();
    updateBasket([]);
  };

  const setNotes = async (note) => {
    updateState({
      notes: note,
    });
  };

  const basketContext: BasketContextInterface = {
    basketLines: state.basketLines.lines,
    lastCreatedId: state.lastCreatedId,
    notes: state.notes,
    addToBasket: addToBasket,
    updateBasketLine: updateBasketLine,
    deleteBasketLine: deleteBasketLine,
    loadBasket: loadBasket,
    updateBasket: updateBasket,
    updateBasketLineForProduct: updateBasketLineForProduct,
    clearBasket: clearBasket,
    setNotes: setNotes,
  };

  return (
    <BasketCtx.Provider value={basketContext}>{children}</BasketCtx.Provider>
  );
}
