/* eslint-disable no-console */
import { Drawer } from "@mui/material";
import React from "react";

import Button from "../../../../reusable/components/Button/button";
import ButtonIncDec from "../../../../reusable/components/ButtonIncDec/button-increment";
import Close from "../../../../reusable/components/icons/Close/close";
import RadioInput from "../../../../reusable/components/RadioInput/radio-input";
import styles from "./product-modal.module.scss"

export interface IProductModalProps {
    onClose?: () => void;
    data: any;
    onIncDec: (id: number, condition: "decrement" | 'increment') => void;
    addToBasket: (item: any) => void;
    disabled?: boolean;
    selectedSize?: number;
    handleChangeSize?: (inputValue: string) => void;
    selectedFlavour?: number;
    handleChangeFlavour?: (inputValue: string) => void
}

const ProductModal: React.FC<IProductModalProps> = ({
    onClose = undefined,
    data,
    onIncDec = undefined,
    addToBasket = undefined,
    disabled = false,
    selectedSize = null,
    handleChangeSize = undefined,
    selectedFlavour = null,
    handleChangeFlavour = undefined,
}) => {
    const renderActionBox = () => {
        if ((data?.products?.length && !selectedSize) || (data?.optionValues?.length && !selectedFlavour)) {
            return (
                <div className={`${styles['actions-container']}`}>
                    <div className={`${styles['increment-container']}`}></div>
                    <div className={styles['button-container']}>
                        <Button
                            text="Add to basket"
                            classes={{
                                root: styles['choose-button']
                            }}
                            onClick={() => addToBasket(data)}
                            disabled
                        />
                    </div>
                </div>
            )
        }

        return (
            <div className={`${styles['actions-container']}`}>
                <div className={`${styles['increment-container']}`}>
                    <ButtonIncDec
                        value={data.count}
                        onIncrement={() => onIncDec(data.id, 'increment')}
                        onDecrement={() => onIncDec(data.id, 'decrement')}
                        disabled={disabled}
                        classes={{
                            root: styles['increment-button']
                        }}
                    />
                </div>
                <div className={styles['button-container']}>
                    <Button
                        text="Add to basket"
                        classes={{
                            root: styles['choose-button']
                        }}
                        onClick={() => addToBasket(data)}
                        disabled={disabled}
                    />
                </div>
            </div>)
    }

    const renderSizes = () => {
        if (!data?.products?.length) return

        return (
            <div className={styles["select-size-container"]}>
                <h1 className="heading-text">{`Select ${data?.selectionType?.type ?? "Size"}`}</h1>
                {data.products.map((size, index) =>
                    <div className={styles['size-item']} key={index}>
                        <RadioInput
                            name={`size-${size.id}`}
                            value={`size-${size.id}`}
                            label={
                                <div className={styles['radio-text']}>
                                    <h1 className="heading-text">{size.name}</h1>
                                    <span className="small-text">£{size.price.toFixed(2)}</span>
                                </div>
                            }
                            isChecked={`flavour-${selectedSize}` === `flavour-${size.id}`}
                            handleChange={handleChangeSize}
                            className={styles['radio-input']}
                            disabled={disabled}
                        />
                    </div>
                )}
            </div>
        )
    }

    const renderFlavours = () => {
        if (!data?.optionValues?.length) return

        const flavoursContent = (
            <div className={styles["select-flavour-container"]}>
                <h1 className="heading-text">Select Flavour</h1>
                {data.optionValues.map((flavour, index) =>
                    <div className={styles['flavour-item']} key={index}>
                        <RadioInput
                            name={`flavour-${flavour.id}`}
                            value={`flavour-${flavour.id}`}
                            label={
                                <div className={styles['radio-text']}>
                                    <h1 className="heading-text">{flavour.name}</h1>
                                </div>
                            }
                            isChecked={`flavour-${selectedFlavour}` === `flavour-${flavour.id}`}
                            handleChange={handleChangeFlavour}
                            className={styles['radio-input']}
                            disabled={disabled}
                        />
                    </div>
                )}
            </div>
        )

        if (data?.sizes) {
            if (selectedSize) {
                return flavoursContent
            }

            return
        }

        return flavoursContent
    }

    return (
        <Drawer
            anchor={'bottom'}
            open
            onClose={onClose}
            className={styles["drawer"]}
        >
            <div className={styles["modal"]}>
                <div className={styles["header-content"]}>
                    <div className={styles["product-name"]}>
                        <h4 className="title_text_2">{data.name}</h4>
                        <p className="small-text">{data?.sizes ? `from £${data.price.toFixed(2)}` : `£${data.price.toFixed(2)}`}</p>
                    </div>
                    <button type="button" className={`${styles['close-icon']}`} onClick={onClose} disabled={disabled}>
                        <Close />
                    </button>
                </div>

                {renderSizes()}
                {renderFlavours()}
                {renderActionBox()}
            </div>
        </Drawer>
    );
}

export default ProductModal;