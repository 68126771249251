import { Modal } from "@mui/material";
import React, { useState } from "react";

import CloseIcon from "../../../../assets/images/CloseIcon.svg";
import Button from "../Button/button";
import styles from "./AllergiesModal.module.scss";
import TextArea from "../Textarea/text-area";

const AllergiesModal = ({
  isModalOpen,
  handleCloseAllergiesModal,
  onContinue,
  notes,
  setNotes,
}) => {
  return (
    <Modal open={isModalOpen}>
      <div className={styles["modal"]}>
        <span className={styles["heading"]}>Notes / Allergies</span>

        <TextArea
          name="notes"
          label="Notes"
          placeholder="Enter order notes, please include any allergies or intolerances you may have"
          onChange={setNotes}
          value={notes}
        />
        <div className={styles["btn-container"]}>
          <Button
            text="Continue"
            classes={{
              root: styles["close-button"],
            }}
            onClick={onContinue}
          />
        </div>
        <button
          onClick={handleCloseAllergiesModal}
          className={styles["closeIcon"]}
        >
          <img src={CloseIcon} alt="" />
        </button>
      </div>
    </Modal>
  );
};

export default AllergiesModal;
