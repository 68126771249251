import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router";
import {titleCase} from "title-case";

import Layout from "../../layout/layout";
import Loading from "../../reusable/components/Loading/Loading";
import Stepper from "../../reusable/components/Stepper/stepper";
import BasketCtx from "../../reusable/context/BasketContext";
import ShopService from "../../services/ShopService";
import styles from "../IceCream/ice-cream.module.scss";
import BasketConfirmation from "./ProductSelector/BasketConfirmation/BasketConfirmation";
import ProductExtras from "./ProductSelector/ProductExtras/ProductExtras";
import ProductOptions from "./ProductSelector/ProductOptions/ProductOptions";
import ProductSelector from "./ProductSelector/ProductSelector";

const DynamicSelector = () => {
    const { identifier } = useParams();
    const basketContext = useContext(BasketCtx);
    
    const [masterCategoryId, setMasterCategoryId] = useState(9);
    const [masterCategorySelectionText, setMasterCategorySelectionText]=useState('');
    const [subCategories, setSubCategories]=useState([]);
    const [selectedProductId, setSelectedProductId] = useState('');
    const [selectedProductName, setSelectedProductName]=useState('');
    const [selectedCategoryName, setSelectedCategoryName]=useState('');
    const [selectedCategoryId, setSelectedCategoryId] = useState('');
    const [productOptions, setProductOptions]=useState([]);
    const [maxProductOptions, setMaxProductOptions] = useState(3); 
    const [selectedProductOptions, setSelectedProductOptions] = useState([]);
    const [showRestrictionModel, setShowRestrictionModel]=useState(false);
    const [currentSelectedProductCount, setCurrentSelectedProductCount]=useState(0);
    const [associatedCategory, setAssociatedCategory] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [extraProducts, setExtraProducts]=useState([]);
    const [step, setStep] = useState('first');
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({
        0: true
    });
    const [selectedOption, setSelectedOption]=useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [basketLineId, setBasketLineId]=useState(null);
    const [isLoading, setIsLoading]=useState(true);
    const [showExtendedHeader, setExtendedHeader]=useState(false);
    useEffect(()=>{
        getDynamicCategory();
    },[])
    
    const getDynamicCategory = () => {
        ShopService.getDynamicCategory(identifier).then((response)=> {
            const masterCategory=response.data.masterCategory;
            setMasterCategoryId(masterCategory.id);
            setMasterCategorySelectionText(masterCategory.selectionText);
            setSubCategories(masterCategory.subCategories);
            setIsLoading(false);
            setExtendedHeader(masterCategory.displayExtendedHeader);
        });
    }
    
    const handleStep = (index: number) => {
        const newCompleted = { ...completed };
        newCompleted[index] = true;
        setCompleted(newCompleted);
        setActiveStep(index);

        const stepPage = index === 0 ? 'category' : index === 1 ? 'product' : index === 2 ? 'extras' : ''
        setStep(stepPage)
    }

    
    const onProductSelected=(product)=>{
        setSelectedProductId(product.id);
        setProductOptions(product.options[0]?.optionValues);
        setMaxProductOptions(product.maxOptionValues);
        setSelectedProductName(product.name);
        setAssociatedCategory(product.associatedCategory);
        setSelectedProduct(product);
    }
    
    const onCategorySelected=(category)=>{
        setSelectedCategoryId(category.id);
        setSelectedCategoryName(category.name);
        setSelectedCategory(category);
    }

    const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });
    
    const onOptionSelected = (product) => {
        setSelectedOption(product);
    }
    
    const onOptionQtyChanged=(product, currentQty) => {
        let currentOptions = [...selectedProductOptions];
        const idsToRemove=[];
        let hasProduct = false;
        
        
        
        const productOption = {
            count:currentQty,
            product: product
        }

        productOption.product.basketName = `${product.name}`;
        // productOption.product.basketName = `${product.name} ${formatTag(product)}`;


        for (let i = 0; i < currentOptions.length; i++) {
            if (currentOptions[i].product.id==product.id) {
                currentOptions[i].count = currentQty;
                if (currentQty==0){
                    idsToRemove.push(currentOptions[i].product.id);         
                }
                hasProduct=true;
            }
        }
        
        if (!hasProduct)
        {
            currentOptions.push(productOption);
        }

        for (let i=0;i<idsToRemove.length; i++){
            currentOptions=currentOptions.filter((row)=>row.product.id!=idsToRemove[i]);
        }

        const totalProductCount = currentOptions.reduce( function(a, b){
            return a + b.count;
        }, 0);
        
        setSelectedProductOptions(currentOptions);
        setCurrentSelectedProductCount(totalProductCount)
    }
    
    const showRestrictionModal = () => {
        setShowRestrictionModel(true);
    }
    
    const hideModalRestriction = () => {
        setShowRestrictionModel(false);
    }
    
    const addExtraProduct = (productExtra) => {
        let currentOptions = [...extraProducts];
        let hasProduct = false;
        const productsToRemove = [];
        const productOption = {
            count:productExtra.count,
            product: productExtra
        };

        for (let i = 0; i < currentOptions.length; i++) {
            if (currentOptions[i].product.id==productExtra.id) {
                if (productExtra.count>0)
                {
                    currentOptions[i].count=productExtra.count;
                    hasProduct=true;
                } else {
                    productsToRemove.push(currentOptions[i].product.id);
                }
            }
        }
        if (hasProduct!=true)        
            currentOptions.push(productOption);

        for (let i=0;i<productsToRemove.length; i++){
            currentOptions=currentOptions.filter((row)=>row.product.id!=productsToRemove[i]);
        }
        
        setExtraProducts(currentOptions);
    }
    
    const getTotalPrice = () => {
        const scoopPrice = selectedProduct?.unitCost;
        const selectedExtras = extraProducts.reduce((sum, item)=> sum + +(item.product.count*item.product.unitCost),0)

        return (1 * +(+scoopPrice + +selectedExtras)).toFixed(2)
    }

    const formatTag=(product)=> {
        const formattedTag = titleCase(product.tag.replace('-', ' '));
        return product.name.toLowerCase().indexOf(formattedTag.toLowerCase()) == -1 ? titleCase(formattedTag) : ''
    }

    const addToBasket = async () => {
        const basketChildren = [];
        
        basketChildren.push(...extraProducts.map((row)=> {
            return {
                name:row.product.name,
                count:row.count,
                price: row.product.unitCost,
                productId: row.product.id
            };
        }));

        basketChildren.push(...selectedProductOptions.map((row)=> {
            return {
                optionValueId:row.product.id,
                name:row.product.basketName,
                count:row.count
            };
        }));

        const basketObject = {
            productId: selectedProductId,
            Count: 1,
            image: selectedCategory?.imagePath,
            name: `${selectedProductName} ${selectedCategoryName}`,
            price: getTotalPrice(),
            children: basketChildren
        }

        await basketContext.addToBasket(basketObject);
        setStep('basket');        
    }
    
    const onUpdateBasketItem = (basketLine) => {
        basketContext.updateBasketLine(basketLine);
    }
    
    const renderContent = () => {
        switch (step) {
            case 'options':
                return <ProductOptions 
                    data={productOptions} 
                    onClick={onOptionSelected} 
                    disabled={false} 
                    onIncDec={onOptionQtyChanged}
                    maximumSelectionsAllowed={maxProductOptions}
                    onShowRestrictionModal={showRestrictionModal}
                    onCloseRestrictionModal={hideModalRestriction}
                    showRestrictionModal={showRestrictionModel}
                    currentNumberSelected={currentSelectedProductCount}
                    selectedOptions={selectedProductOptions}
                    selectedCategoryName={selectedCategoryName}
                    selectedProductName={selectedProductName}
                    handleStep={handleStep}
                    setStep={setStep}
                    scrollTop={scrollTop}
                    showExtendedHeader={showExtendedHeader}
                    selectedCategory={selectedCategory}
                />;
            case 'extras':
                return (<ProductExtras 
                    onClick={addExtraProduct} 
                    associatedCategory={associatedCategory}
                    selectedOptions={selectedProductOptions}
                    selectedCategoryName={selectedCategoryName}
                    selectedProductName={selectedProductName}
                    selectedExtras={extraProducts}
                    addToBasket={addToBasket}
                />);
            case 'basket':
                return (<BasketConfirmation 
                    scrollTop={scrollTop}
                    onUpdateBasketItem={onUpdateBasketItem}
                    basketId={basketLineId}
                />);
            default:
                return (<ProductSelector 
                    masterCategorySelectionText={masterCategorySelectionText} 
                    masterCategoryChildren={subCategories}
                    onCategorySelected={onCategorySelected}
                    onProductSelected={onProductSelected}
                    selectedProductId={selectedProductId}
                    selectedCategoryId={selectedCategoryId}
                    handleStep={handleStep}
                    setStep={setStep}
                    scrollTop={scrollTop}
                    selectedCategoryName={selectedCategoryName}
                    selectedProductName={selectedProductName}
                />);
        }
    }
    
    return (<Layout>
        <div className={styles['container']}>
            <div className={styles['content']}>
                {isLoading ? <Loading isLoading /> : step !== 'basket' ?
                    <Stepper
                        steps={[1, 2, 3]}
                        activeStep={activeStep}
                        completed={completed}
                        onClick={handleStep}
                        classes={{ root: styles.stepper }}
                    /> : null}
                {renderContent()}
            </div>
        </div>
    </Layout>)
}

export default DynamicSelector;