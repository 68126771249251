import React, { useEffect, useRef } from 'react'

import Button from '../../../reusable/components/Button/button';
import SectionHeading from '../../../reusable/components/SectionHeading/section-heading';
import styles from './extras.module.scss'
import SauceList from './SauceList/sauce-list';
import ToppingList from './ToppingList/topping-list';

const Extras = ({
    getName = undefined,
    flavourList = [],
    scoopList = [],
    selectedScoopId = null,
    coneList = [],
    selectedConeId = null,
    scrollTop = undefined,
    setStep = undefined,
    sauceList = [],
    handleSelectSauce = undefined,
    toppingList = [],
    handleSelectTopping = undefined,
    addItemToBasket = undefined
}) => {
    const addToBasket = () => {
        addItemToBasket();
        scrollTop();
        setStep('basket');
    }

    const toppingRef = useRef(null)
    const btnRef = useRef(null)
    const scrollToppingBox = () => toppingRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    const scrollBtnBox = () => btnRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })

    const handleSelectSauceBox = (id: number) => {
        handleSelectSauce(id)

        setTimeout(() => {
            scrollToppingBox()
        }, 300);
    }

    const handleSelectToppingBox = (id: number) => {
        handleSelectTopping(id)

        setTimeout(() => {
            scrollBtnBox()
        }, 300);
    }

    return (
        <>
            <div className={styles['section-header']}>
                <SectionHeading header="Add extras" description='Add any extras such as sauces and toppings' />
            </div>
            <div className={styles['section-item-header']}>
                <SectionHeading header="Sauces" />
            </div>
            <SauceList data={sauceList} onClick={handleSelectSauceBox} />
            <div className={styles['section-item-header']} ref={toppingRef}>
                <SectionHeading header="Toppings" />
            </div>
            <ToppingList data={toppingList} onClick={handleSelectToppingBox} />
            <div className={styles['next-steps-container']}>
                <h4 className={`heading-text`}>
                    You have selected:
                </h4>
                <p className={`body-text`}>1x {getName(scoopList, selectedScoopId)} {getName(coneList, selectedConeId)}</p>
                {flavourList.filter(el => el.selected).map(item =>
                    <p className={`body-text`} key={item.id}>{item.count}x {item.name}</p>
                )}
                {sauceList.filter(el => el.selected).map(item =>
                    <p className={`body-text`} key={item.id}>1x {item.name}</p>
                )}
                {toppingList.filter(el => el.selected).map(item =>
                    <p className={`body-text`} key={item.id}>1x {item.name}</p>
                )}
                <div className={styles['button-container']} ref={btnRef}>
                    <Button
                        text="Add to basket"
                        classes={{
                            root: styles['choose-button']
                        }}
                        onClick={addToBasket}
                    />
                </div>
            </div>
        </>
    )
}

export default Extras