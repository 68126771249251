import React, {useState} from "react";

import ButtonIncDec from "../../../../../../reusable/components/ButtonIncDec/button-increment";
import styles from "../../../ProductExtras/ProductExtrasLine/ProductExtrasLine.module.scss";

const ProductOptionLineItem = (
    {
        listItem,
        showRestrictionModal,
        checkSelectedCategory,
        onSelected
    }
) => {
    const [selectedQty, setSelectedQty] = useState(1);    
    const [isSelected, setIsSelected]=useState(false);
    return ((
        <li
            key={listItem.id}
        >
            <div className={`${styles['list-item']} ${isSelected ? styles["list-item-selected"] : ""}`}
                 onClick={() => {
                     listItem.count=selectedQty;
                     onSelected(listItem);
                     setIsSelected(true);
                 }}
            >
                <div className={styles['list-item-image']}>
                    <img src={listItem.imagePath} alt={listItem.imagePath} />
                </div>
                <div className={styles['list-item-content']}>
                    <div className={`small-text ${styles['list-item-header']}`}>
                        {listItem.name}
                    </div>
                    <div className={`title_text_3 ${styles['list-item-price']}`}>
                        {listItem.unitCost ? '£' + listItem.unitCost.toFixed(2) : '-'}
                    </div>
                </div>
            </div>
            <div className={styles['increment-container']}>
                {isSelected ?
                    <ButtonIncDec
                        value={`${selectedQty}`}
                        onIncrement={(e) => {
                            e.stopPropagation()
                            const number = selectedQty+1;
                            listItem.count=number;
                            if (!checkSelectedCategory(listItem)) {
                                setSelectedQty(number);
                                onSelected(listItem);
                            } else {
                                showRestrictionModal();
                            }
                        }}
                        onDecrement={(e) => {
                            e.stopPropagation()
                            const number = selectedQty-1;
                            if(number>=0) {
                                setSelectedQty(number)
                                listItem.count=number;
                                onSelected(listItem); 
                            }
                            
                            setIsSelected(number>0);
                            
                            if (number==0) {
                                setSelectedQty(1);
                            }
                        }}
                        disabled={false}
                        classes={{
                            root: styles['increment-button']
                        }}
                    />
                    : null}
            </div>
        </li>
    ))
}

export default ProductOptionLineItem;