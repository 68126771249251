import React from 'react';

interface ISectionArrowProps {
    fillColor?: string;
}

const SectionArrow: React.FC<ISectionArrowProps> = ({
    fillColor = "#DF668A"
}) => {
    return (
        <svg width="33" height="188" viewBox="0 0 33 188" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.4135 120.739L24.1821 119.439C22.9097 118.929 22.0781 117.691 22.0781 116.312V0H18.4115V116.317C18.4115 117.696 17.5799 118.929 16.3075 119.445L13.0761 120.745C13.0761 120.745 13.0649 120.756 13.0594 120.756C5.56976 123.609 0.206494 130.794 0 139.268C0 139.554 0.223237 139.773 0.502283 139.773C0.781329 139.773 1.00457 139.548 1.00457 139.274C1.18316 132.139 5.207 125.963 11.0726 122.763C14.4992 120.891 18.8189 123.161 19.0309 127.073C19.0365 127.19 19.0421 127.308 19.0421 127.431C19.0421 131.046 18.205 132.145 18.1213 133.591C18.0543 134.785 18.9137 135.877 20.1025 135.961C21.3526 136.051 22.3962 135.054 22.3962 133.815C22.3962 132.251 21.4698 131.226 21.4698 127.431C21.4698 124.029 24.0705 121.233 27.3912 120.964C27.514 120.952 27.5419 120.79 27.4247 120.739" fill={fillColor} />
            <path d="M21.3303 165.139C21.3303 168.821 22.2288 169.813 22.2288 171.332C22.2288 172.487 21.2968 173.417 20.1527 173.417C19.0086 173.417 18.0766 172.481 18.0766 171.332C18.0766 169.813 18.9751 168.821 18.9751 165.139C18.9751 161.658 16.1791 158.839 12.7133 158.839H7.30542L18.5342 186.896C19.1202 188.364 21.1852 188.364 21.7712 186.896L32.9944 158.839H27.5865C24.1207 158.839 21.3247 161.658 21.3247 165.139H21.3303Z" fill={fillColor} />
        </svg>
    );
};

export default SectionArrow;

