import axios from "axios";

const createBasket = (basketLine) => {
    return axios.post("/api/Basket/AddItemToBasket", basketLine);
}

const getBasketLines = () => {
    return axios.get("/api/Basket/GetBasket");
}

const deleteBasketLine = (id) => {
    return axios.delete("/api/Basket/"+id)
}

const updateBasketLine = (updateObject) => {
    return axios.post("/api/Basket/UpdateLineQty", updateObject)
}

const updateBasketLineForProduct = (updateObject) => {
    return axios.post("/api/Basket/UpdateLineQtyForProduct", updateObject)
}

const getBasketClientSecret = () => {
    return axios.get("/api/Basket/secret")
}
const completeBasket = (orderRequest) => {
    return axios.post("/api/Basket/complete", orderRequest)
}

const clearBasket =() => {
    return axios.post("/api/Basket/ClearBasket");    
}

export default {
    createBasket,
    getBasketLines,
    updateBasketLine,
    deleteBasketLine,
    getBasketClientSecret,
    completeBasket,
    clearBasket,
    updateBasketLineForProduct
}