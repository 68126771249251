import React from 'react';

import styles from './text-area.module.scss';

export interface ITextAreaProps<T = any> {
  value?: T;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  id?: string;
  className?: string;
  autoFocus?: boolean;
  error?: any
}

export const TextArea: React.FC<ITextAreaProps> = ({
  value = '',
  onChange = undefined,
  name = '',
  label = undefined,
  placeholder = undefined,
  disabled = false,
  id = undefined,
  className = '',
  autoFocus = false,
  error
}) => (
  <div className={styles['container']}>
    {label ?
      <div
        className={`title_text_2 ${styles['label']}`}>
        {label}
      </div>
      : null}
    <textarea
      id={id}
      name={name}
      placeholder={placeholder}
      disabled={disabled}
      value={value}
      onChange={onChange}
      className={`body-text ${styles['textarea']} ${error ? styles['input-error'] : ''} ${className}`}
      autoFocus={autoFocus}
      autoComplete={'none'}
    />
    {error?.length ? <span className={`body-text ${styles['error-message']}`}>{error}</span> : null}
  </div>
);

export default TextArea;
