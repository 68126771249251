import { Modal } from "@mui/material";
import React, { useState } from "react";

import CloseIcon from "../../../../assets/images/CloseIcon.svg";
import Button from "../Button/button";
import styles from "./WaitTimeModal.module.scss";

const WaitTimeModal = ({
  waitTimes,
  isModalOpen,
  handleCloseWaitTimeModal,
}) => {
  return (
    <Modal open={isModalOpen}>
      <div className={styles["modal"]}>
        <span className={styles["heading"]}>Current wait times:</span>
        <div className={styles["wait-times-list"]}>
          {waitTimes?.map((item: any) => (
            <p>{`${item.description}: ${item.waitInMinutes} minutes`}</p>
          ))}
        </div>
        <div className={styles["btn-container"]}>
          <Button
            text="Confirm"
            classes={{
              root: styles["close-button"],
            }}
            onClick={handleCloseWaitTimeModal}
          />
        </div>
        <button
          onClick={handleCloseWaitTimeModal}
          className={styles["closeIcon"]}
        >
          <img src={CloseIcon} alt="" />
        </button>
      </div>
    </Modal>
  );
};

export default WaitTimeModal;
