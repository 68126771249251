import React, {useState} from "react";

import ExclusiveMark from "../../../../../../assets/images/exclusive.svg";
import NewMark from "../../../../../../assets/images/new.svg";
import ButtonIncDec from "../../../../../reusable/components/ButtonIncDec/button-increment";
import styles from "../ProductOptions.module.scss";

const ProductOptionLine = ({
    product,
    onClick,
    onIncDec,
    disabled,
    maxAllowed,
    currentNumberSelected,
    onShowRestrictionModal,
    selectedItem
}) => 
{    
    const [selected, setSelected] = useState(selectedItem);
    const [selectedQty, setSelectedQty] = useState(1);
    
    const canAddMore = currentNumberSelected < maxAllowed;
    
    return (<li
        key={product.id}
        className={`${styles['list-item-container']}`}
    >
        {product.isNew ? <img className={styles['new-mark']} src={NewMark} alt="new mark" /> : null}
        {product.isExclusive ? <img className={styles['exclusive-mark']} src={ExclusiveMark} alt="exclusive mark" /> : null}
        <div
            className={`${styles['list-item']} ${selected ? styles["list-item-selected"] : ""}`}
            onClick={() => {
                if (canAddMore)
                {
                    onIncDec(product,1,'increment')
                    setSelected(true);
                }    
                else 
                    onShowRestrictionModal();
            }}
        >
            <div className={styles['list-item-image']}>
                <img src={product.imagePath} alt={product.imagePath} />
            </div>
            <div className={styles['list-item-content']}>
                <div className={`small-text ${styles['list-item-header']}`}>
                    {product.name}
                </div>
            </div>
        </div>
        <div className={styles['increment-container']}>
            {selected ?
                <ButtonIncDec
                    value={`${selectedQty}`}
                    onIncrement={(e) => {
                        e.stopPropagation()
                        if (!canAddMore) {
                            onShowRestrictionModal();
                        } else {
                            const number = selectedQty+1;
                            setSelectedQty(number)
                            onIncDec(product,number, 'increment')
                        }
                    }}
                    onDecrement={(e) => {
                        e.stopPropagation()
                        const number = selectedQty-1;
                        if(number>=0) {
                            setSelectedQty(number)
                            onIncDec(product,number,'increment')
                        }

                        setSelected(number>0);
                    }}
                    disabled={disabled}
                    classes={{
                        root: styles['increment-button']
                    }}
                />
                : null}
        </div>
    </li>);    
}

export default ProductOptionLine;