import React from 'react'

import styles from './cone-list.module.scss'

export interface IConeListProps {
    data: Array<any>;
    selectedId: number;
    onClick: (id: number, selectionText:string) => void
}

export const ConeList: React.FC<IConeListProps> = ({
    data = [],
    selectedId = null,
    onClick = undefined
}) => {
    return (
        <ul className={styles['list']}>
            {data.map((listItem) => (
                <li key={listItem.id} className={`${styles['list-item']} ${listItem.id === selectedId ? styles["list-item-selected"] : ""} ${listItem.isActive !== true ? styles["list-item-disabled"] : ""}`} onClick={() => {
                    if (listItem.isActive !== true) {
                        return;
                    }
                    onClick(listItem.id, listItem.selectionText)
                }}>
                    <div className={styles['list-item-image']}>
                        <img src={listItem.imagePath} alt={listItem.imagePath} />
                    </div>
                    <div className={styles['list-item-content']}>
                        <div className={`small-text ${styles['list-item-header']}`}>
                            {listItem.name}
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    )
}

export default ConeList