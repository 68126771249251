import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Basket from './pages/Basket/basket';
import Categories from './pages/Categories/categories';
import CategoryContents from "./pages/Categories/CategoryContents/CategoryContents";
import DynamicSelector from "./pages/DynamicSelector/DynamicSelector";
import Home from './pages/Home/home';
import IceCream from './pages/IceCream/ice-cream';
import Success from './pages/Success/success';
import ScrollToTop from './reusable/utils/ScrollToTop';
import RememberLocation from "./pages/Location/RememberLocation";

export default function AppRouter() {
    return (
        <Router>
            <ScrollToTop>
                <Routes>
                    <Route>
                        <Route path="" element={<Home />} />
                        <Route path="categories" element={<Categories />} />
                        <Route path="categories/:categoryName" element={<CategoryContents />} />
                        <Route path="dynamic/:identifier" element={<DynamicSelector />} />
                        <Route path="ice-cream" element={<IceCream />} />
                        <Route path="success/:orderId" element={<Success />} />
                        <Route path="location/:id" element={<RememberLocation />} />
                        <Route path="basket" element={<Basket />} />
                        <Route path="*" element={<p>unknown</p>} />
                    </Route>
                </Routes>
            </ScrollToTop>
        </Router>
    )
}
