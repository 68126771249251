import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom';

import Button from '../../../reusable/components/Button/button';
import ButtonIncDec from '../../../reusable/components/ButtonIncDec/button-increment';
import SectionHeading from '../../../reusable/components/SectionHeading/section-heading';
import BasketCtx from "../../../reusable/context/BasketContext";
import styles from './basket.module.scss'

const Basket = ({
    scrollTop = undefined,
    handleIncDecOrderProduct = undefined,
    resetProduct = undefined
}) => {
    const navigate = useNavigate()
    const basketContext = useContext(BasketCtx);
    return (
        <>
            <div className={`${styles['section-header']} ${styles['section-basket-header']}`}>
                <SectionHeading header="Added to basket!" />
            </div>
            <div className={styles['basket-content']}>
                <div className={styles['basket-items']}>
                    {basketContext.basketLines.map((_, index) => {
                        const children = _.children?.map((child, childIndex) => {
                            return (<p className={`small-text`} key={childIndex}>{child.count == 0 ? 1 : child.count}x {child.name}</p>);
                        });


                        return (<div className={styles['basket-item']} key={index}>
                            <img src={_.image} alt="basket product" />
                            <h4 className={`small-text`}>
                                {_.count} x {_.name}
                            </h4>
                            {children && children}
                            <div className={`title_text_2 ${styles['total-price']}`}>
                                £{(_.count * _.price).toFixed(2)}
                            </div>
                            <div className={styles['increment-container']}>
                                <ButtonIncDec
                                    key={_.id}
                                    value={`${_.count}`}
                                    onIncrement={(e) => {
                                        e.stopPropagation()
                                        handleIncDecOrderProduct('increment', _)
                                    }}
                                    onDecrement={(e) => {
                                        e.stopPropagation()
                                        handleIncDecOrderProduct('decrement', _)
                                    }}
                                    classes={{
                                        root: styles['increment-button']
                                    }}
                                />
                            </div>
                        </div>);
                    })}
                </div>
                <div className={styles['basket-button-container']}>
                    <Button
                        text="Add another item"
                        classes={{
                            root: styles['add-icecream-button']
                        }}
                        onClick={() => {
                            scrollTop();
                            resetProduct()
                            navigate('/categories')
                        }}
                    />
                    <Button
                        text="Go to basket"
                        classes={{
                            root: styles['proceed-payment-button']
                        }}
                        onClick={() => {
                            resetProduct()
                            navigate('/basket')
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default Basket