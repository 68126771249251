import React from 'react'

import ArrowRight from '../../../../reusable/components/icons/ArrowRight/arrow-right';
import styles from './venu-number.module.scss'

export interface IVenuNumberProps {
    numList: Array<any>;
    venuListOpened: boolean;
    handleSelectVanuNumber?: (id: number) => void;
    selectedVanuNumber?: any;
    setVenuListOpened?: any
}

export const VenuNumber: React.FC<IVenuNumberProps> = ({
    numList = [],
    venuListOpened = false,
    selectedVanuNumber = null,
    handleSelectVanuNumber = undefined,
    setVenuListOpened = undefined
}) => {
    return (
        <div className={styles['container']}>
            <div className={styles['content']} onClick={() => setVenuListOpened(prev => !prev)}>
                <div className='title_text_2'>Location Number</div>
                <div className={styles['right-part']}>
                    <div className='title_text_2'>
                        {selectedVanuNumber
                            ? selectedVanuNumber.name
                            : "select"}
                    </div>
                    <div className={`${styles['arrow-icon']} ${venuListOpened ? styles['open'] : ''}`}>
                        <ArrowRight />
                    </div>
                </div>
            </div>
            {venuListOpened ?
                <ul className={`${styles['list']}`}>
                    {numList.map((num, index) =>
                        <li className={`${styles['list-item']} ${selectedVanuNumber?.id === num.id ? styles['selected'] : ''}`} key={index} onClick={() => {
                            handleSelectVanuNumber(num)
                            setVenuListOpened(false)
                        }}>
                            {num.name}
                        </li>
                    )}
                </ul>
                : null}
        </div>
    )
}

export default VenuNumber