import React, {useRef, useState} from "react";

import ButtonIncDec from "../../../../../reusable/components/ButtonIncDec/button-increment";
import SectionHeading from "../../../../../reusable/components/SectionHeading/section-heading";
import ValidationModal from "../../../../../reusable/components/ValidationModal/ValidationModal";
import ProductOptionLineItem from "../../ProductOptions/ProductOptionLine/ProductOptionLineItem/ProductOptionLineItem";
import styles from "./ProductExtrasLine.module.scss";

const ProductExtrasLine = ({ associatedCategory, onClick, selectedExtras }) => {
    const endRef=useRef(null);
    
    const [showRestrictionModal, setShowRestrictionModal] = useState(false);
    const [currentCategory, setCurrentCategory] = useState(null);
    
    const onSelected=(listItem) => {
        setTimeout(()=>{
            endRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100);
        
        onClick(listItem);
    }
    
    const maxSelectionsAllowed=associatedCategory.maxSelection || -1;
    
    const checkSelectedProduct = (productToCheck) => {
        return selectedExtras.filter((products)=>products.product.id==productToCheck.id).length>0;
    }
    
    const checkSelectedCategory = (productToCheck) => {
        if (maxSelectionsAllowed==-1)
            return false;
        
        const currentSelectedExtras = selectedExtras.filter((row)=>row.product.categoryId==productToCheck.categoryId).reduce(function(a, b){
            return a + b.count;
        }, 0)+1>maxSelectionsAllowed;
        
        return currentSelectedExtras;
    }
    
    const onCloseRestrictionModal = () => {
        setShowRestrictionModal(false);
    }
    
    return (
    <>
        <div className={`${styles['section-item-header']}}`}>
            <SectionHeading header={maxSelectionsAllowed>1 ? `Select ${maxSelectionsAllowed} ${associatedCategory.name}` : associatedCategory.name} />
        </div>

        <ul className={`${styles['list']} ${styles['list-wrapper']}`}>
            {associatedCategory.products.map((listItem) => 
                <ProductOptionLineItem 
                    listItem={listItem}
                    showRestrictionModal={()=>{
                        setShowRestrictionModal(true);
                    }}
                    checkSelectedCategory={checkSelectedCategory}
                    onSelected={onSelected}
                />)}
        </ul>

        <ValidationModal
            maxFlavours={maxSelectionsAllowed}
            isModalOpen={showRestrictionModal}
            handleCloseValidationModal={onCloseRestrictionModal}
            type={`of the ${associatedCategory.name} category`}
            secondaryType={associatedCategory.name}
        />
        
        <div ref={endRef}></div>
    </>);
}

export default ProductExtrasLine;