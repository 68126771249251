import React, {useState} from "react";

import ButtonIncDec from "../../../../../reusable/components/ButtonIncDec/button-increment";
import styles from "../../../../IceCream/IceCreamBasket/basket.module.scss";

const BasketConfirmationLine = ({
    basketLine = undefined, 
    onUpdateBasketItem = undefined
                                }) => {
    const children = basketLine.children?.map((child, childIndex) => {
        return (<p className={`small-text`} key={childIndex}>{child.count == 0 ? 1 : child.count}x {child.name}</p>);
    });

    const [itemCount, setItemCount] = useState(basketLine.count);

    return (<div className={styles['basket-item']} key={basketLine.id}>
    <img src={basketLine.image} alt="basket product" />
    <h4 className={`small-text`}>
    {basketLine.count} x {basketLine.name}
    </h4>
    {children && children}
    <div className={`title_text_2 ${styles['total-price']}`}>
    £{(basketLine.count * basketLine.price).toFixed(2)}
    </div>
    <div className={styles['increment-container']}>
    <ButtonIncDec
        key={basketLine.id}
    value={itemCount}
    onIncrement={(e) => {
        e.stopPropagation()
        const number = itemCount+1;
        setItemCount(number);
        onUpdateBasketItem({
            Id: basketLine.id,
            Qty: number
        })
    }}
    onDecrement={(e) => {
        e.stopPropagation()
        const number = itemCount-1;
        setItemCount(number);
        onUpdateBasketItem({
            Id: basketLine.id,
            Qty: number
        })
    }}
    classes={{
        root: styles['increment-button']
    }}
    />
    </div>
    </div>);
}

export default BasketConfirmationLine;