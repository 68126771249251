import React from 'react';

import styles from './button.module.scss';

export interface IButtonProps {
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit';
  id?: string;
  /* important props */
  classes: {
    root: string
  };
  text: string;
}

export const Button: React.FC<IButtonProps> = ({
  disabled = false,
  onClick = undefined,
  type = 'button',
  text = '',
  id = undefined,
  classes: {
    root
  },
}) => {
  return (
    <button
      id={id}
      onClick={onClick}
      disabled={disabled}
      type={type}
      className={`heading-text ${styles['container']} ${root}`}
    >
      <svg className={styles['button-background']} viewBox="0 0 169 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.66842 8.20599C13.3648 4.29382 19.4419 2.2086 25.796 1.42616C30.0325 0.903904 51.7312 0.817817 90.8932 1.16503C113.27 1.94747 129.339 2.20764 151.163 6.9013C156.135 7.9439 161.383 9.50973 164.699 13.1598C168.567 17.5923 168.567 24.8944 166.357 30.1094C164.147 35.3243 156.964 38.192 151.439 39.4967C145.915 40.8004 140.114 41.0606 134.311 41.3217C123.815 41.5829 84.4253 41.843 73.9277 42.1042C66.7446 42.3643 48.4489 42.6255 41.2659 42.8866C32.9791 43.1468 24.4153 43.4079 16.6804 40.8004C8.94433 38.1929 2.03833 31.9344 1.20942 24.112C0.657586 18.1146 3.97205 12.1172 8.66842 8.20503V8.20599Z" fill="#6FAFB9" stroke="#6FAFB9" strokeWidth="2" />
      </svg>
      <div className={`heading-text ${styles['button-text']}`}>{text}</div>
    </button>
  )
}

export default Button; 